.ml_manual_kb_creator{
  font-family: Spartan;
  color: $dark;
  position: relative;
  .ml-modal-bg{
    background-color: #01393a96;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    z-index: 110;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .ml_close{
      background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position:50% 50%;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .ml-modal-content-wrap{
      padding: 40px 50px;
      .ml_metadatos, .ml_editor_assign{
        height: 50vh;
        width: 60vw;
        overflow-y: auto;
      }
      .ml_slide_container{
        margin-bottom: 30px;
        .ml_btn-selected{
          z-index: 50;
        }
        .ml_btn-option{
          z-index: 90;
        }
      }

      .ml_editor_assign{
        .ml_left_side, .ml_right_side{
          width: 49%;
          display: inline-block;
        }
        .ml_left_side{
          border-right: 1px solid var(--primary);
          padding-right: 5px;
        }
        .ml_paragraph-h{
          padding-left: 40px;
          margin-bottom: 0px;
        }
        .head_line{
          height: 1px;
          width: 100%;
          background-color: var(--primary);
        }
        .ml_header_list{
          box-sizing: border-box;
          padding: 5px 15px;
          span{
            display: inline-block;
            &.ml_head_id{
              width: 8%;
            }
            &.ml_head_name{
              width: 60%; // must be 40 if permission is enabled
            }
            &.ml_head_permission{
              width: 40%;
            }
          }
        }
        .ml_list_container{
          box-sizing: border-box;
          padding: 5px 15px;
          position: relative;
          height: 39vh;
          overflow-y: auto;
          .ml_row{
            margin: 15px 0px;
            height: 40px;
            position: relative;
            span{
              display: inline-block;
              &.ml_paragraph{
                // padding-top: 10px;
                font-size: 11px;
              }
              &.ml_row_id{
                width: 8%;
              }
              &.ml_row_name{
                width: 60%; // must be 40 if permission is enabled
              }
              &.ml_row_permission{
                width: 40%;
                .ml_input_wrap{
                  display: inline-flex;
                  margin: 0px 8px;
                  input, label{
                    cursor: pointer;
                  }
                }
              }
              &.ml_add_icon{
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/add.svg);
                width: 40px;
                height: 40px;
                position: absolute;
                top: -12px;
                cursor: pointer;
                &:hover{
                  background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/mas.svg');
                }
              }
              &.ml_remove_icon{
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/tacho_basura.svg);
                width: 40px;
                height: 40px;
                position: absolute;
                top: -12px;
                cursor: pointer;
                &:hover{
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
      .ml_left_wrap,.ml_right_wrap{
        display: inline-block;
        width: 48%;
        vertical-align: top;
      }
      p{
        padding-bottom: 10px;
      }
      input{
        margin-bottom: 10px;
      }

      .ml_btn-wrap{
        padding-top: 20px;
        padding-left: 0px;
        margin-left: 0px;
        padding-right: 70px;
        padding-bottom: 20px;
      }

      .ml_select_wrap{
        width: 180px;
      }
      .ml_input_wrap input{
        width: 60%;
      }

      .ml_metadata_wrap{
        width: 100%;

        div{
          display: inline-block;
          width: 49%;
        }
      }

      .ml-options{
        max-height: 300px;
        overflow-y: scroll;
        z-index: 99;
      }

      .prg-affected-container{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-block: 10px;
        max-height: 300px;
        overflow: auto;

        .prg-item{
          display: flex;
          align-items: center;
          column-gap: 5px;
          cursor: pointer;

          p, div{ pointer-events: none; }

          p {
            padding: 0px;
          }

          &:hover{
            p { font-weight: bold; }
            div{ transform: scale(1.1) rotate(-90deg); }
          }

          .ml_navigate_to_prg{
            width: 30px;
            height: 30px;
            display: block;
            transform: rotate(-90deg);
            background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg);
          }
        }
      }
    }

    .ml_close:hover{
      background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
    }

    .ml-modal-card{
      background-color: white;
      // min-height: 40%;
      max-height: 80%;
      border-radius: 30px;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 30px;
      width: auto;
      min-width: 300px;
    }

    .ml_modal_correct_law{
      p{
        width: 90%;
        max-width: 700px;
      }
      textarea{
        width: 90%;
        max-width: 700px;
        background-color: $inputblue;
        padding: 10px;
        border: 0px;
        border-radius: 10px;
      }
    }

    .ml_modal_report_error{
      p{
        width: 90%;
        max-width: 700px;
      }
      textarea{
        width: 90%;
        max-width: 700px;
        background-color: $inputblue;
        padding: 10px;
        border: 0px;
        border-radius: 10px;
      }
      .ml_btn-wrap{
        display: inline-block;
        padding-right: 15px;
      }
    }

    .ml_modal_validate_law{
      text-align: center;

      .ml_btns-wrap{
        margin: auto;
        div{
          display: inline-block;
          padding-right: 10px;
        }
      }
    }

    .ml_modal_revision_law{
      text-align: center;

      .ml_btns-wrap{
        margin: auto;
        div{
          display: inline-block;
          padding-right: 10px;
        }
      }
    }

    .ml_modal_return_law{
      p{
        width: 90%;
        max-width: 700px;
      }
      textarea{
        width: 90%;
        max-width: 700px;
        background-color: $inputblue;
        padding: 10px;
        border: 0px;
        border-radius: 10px;
      }
      .ml_btn-wrap{
        display: inline-block;
        padding-right: 15px;
      }
    }


    .ml_data_bk{
      margin-top: 20px;
    }

    .ml_btn_wrap{
      display: block;
      width: 100%;
      margin: auto;
      text-align: center;
      margin-top: 30px;
    }




  }

  .ml_modal_revision{

    text-align: center;

    .ml_close{
      background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position:50% 50%;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .mq-modal-card{
      min-height: 20px;
      padding: 30px;
    }

    .ml_message_wrap{
      .ml_card-h{
        margin-bottom: 20px;
      }
      p{
        color: $dark;
        text-align: center;
      }
      .ml_txt_errores{
        margin-top: 20px;
      }
      .ml_txt_errores{
        p{
          color: var(--accent);
        }

        p::before{
          content: "";
          width: 30px;
          height: 30px;
          display: inline-block;
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/p/alerta.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          background-position:50% ;
          vertical-align: middle;

        }
      }

    }

  }

  .ml_header{
    margin-top: 30px;
    vertical-align: top;

    .ml_l_header{
      width: 68%;
      @include shadow;
      padding: 20px;
      border-radius: 20px;
      position: relative;
      display: inline-block;
      vertical-align: top;

      .ml_headline-3{
        margin-bottom: .6em;
        margin-top: .6em;
      }

      img{
        width: 50px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        @include shadow;
        border-radius: 10px;

      }
    }
    .ml_r_header{
      width: 25%;
      @include shadow;
      padding: 20px;
      border-radius: 20px;
      position: relative;
      display: inline-block;
      text-align: left;

      form{
        display: inline-block;
        margin-top: 10px;
      }
      .ml_input-psw-icon-wrap {
        &.disabled{
          pointer-events: none;
          opacity: 0,5;
          filter: grayscale(100%);
        }
        input{
          margin-left: 0px;
        }
      }

      .ml_btn-case-sensitive{
        color: var(--primary);
        padding: 10px;
        border-radius: 5px;
        background-color: white;
        @include shadow;
        cursor: pointer;
        &.i_active{
          color: $white;
          background-color:var(--primary);
          &:hover{
            background-color: white;
            color: var(--primary);
            @include shadow;
          }
        }

        &:hover{
          color: $white;
          padding: 10px;
          border-radius: 5px;
          background-color:var(--primary);
          cursor: pointer;
        }
        strong{
          pointer-events: none;
        }
      }
    }
  }

  .ml_header_status_wrap{
    width: 96%;
    @include shadow;
    padding: 20px;
    border-radius: 20px;
    margin: 10px 0px 10px 0px;

    .ml_bullet{
      width: 10px;
      height: 10px;
      border-radius: 50px;
      display: inline-block;
      margin-top: 14px;
      margin-right: 10px;
    }
  }

  .ml_data_base_container{
    width: 96%;
    @include shadow;
    padding: 20px;
    border-radius: 20px;
    margin: 10px 0px 10px 0px;


    .ml_wysiwyg-container{
      padding: 0 $p_xs $p_xs $p_xs;
      box-sizing: border-box;
      position: relative;
      // background-color: $light_gray;
      display: flex;
      border-radius: $radius;
    }

    .ml_wysiwyg-header{
      height: 100vh * 0.05;
    }

    .ml_wysiwyg-body{
      height: 60vh;
      border:1px solid var(--lprimary);
      border-radius: 20px;
      box-sizing: border-box;
      padding: $p_xl $p_sm $p_sm $p_sm;
      overflow-y: auto;
      p.e_node{ padding: 5px 0px; }

      &.loading{
        opacity: 0.3;
      }
      &.not_save{
        background-color: $inputblue;
      }
      &:focus-visible{
        outline: none;
      }
      .node {
        padding: 3px 5px;
      }
      .p_selected{
        background-color: var(--primary5);
        padding: 3px 5px;
        border-radius: $radius;
      }
      .ml_highlightWord{
        font-weight: bold;
        background-color: var(--warning);
        border-radius: $radius;
        pointer-events: none;
      }
      .ml_highlightParagraph {
        border-radius: $radius;
        background-color: $inputblue;
      }

      .ml_highlightParagraphSelected {
        padding: 10px 0px;
        border-radius: $radius;
        background-color: $inputblue;
      }

      .ml_word_not_found {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        color: gray;
      }

      // Tipos de nodo:
      [data-mv]::before{
        content: attr(data-mv);
        color: var(--primary);
        margin-right: 5px;
      }

      .PREAMBULO{
        font-size: 1.4em;
        color: $ldark;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .PREAMBULO::after{
        content: 'PREA';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }

      .TITULO_PRELIMINAR{
        font-size: 1.3em;
        color: $ldark;
        font-weight: 800;
        margin-bottom: 10px;
      }
      .TITULO_PRELIMINAR::after{
        content: 'TIT_PREL';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }
      .LIBRO{
        font-size: 1.3em;
        font-weight: 600;
      }
      .LIBRO::after{
        content: 'LIB';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }
      .TITULO{
        font-size: 1.1em;
        color: $ldark;
        font-weight: 800;
      }
      .TITULO::after{
        content: 'TIT';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }
      .CAPITULO{
        font-size: 1em;
        font-weight: 700;
      }
      .CAPITULO::after{
        content: 'CAP';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }

      .SECCION{
        font-size: .9em;
        font-weight: 800;
      }
      .SECCION::after{
        content: 'SEC';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }
      .SUBSECCION{
        font-size: .9em;
        font-weight: 600;
      }
      .SUBSECCION::after{
        content: 'SUB-SEC';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
        font-weight: 800;
      }
      .paragraph_title_note{
        font-size: .7em;
        color: $grey;
        font-weight: 500;
      }
      .paragraph_title_note::after{
        content: 'TIT NDP';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }

      .ARTICULO{
        font-weight: 700;
      }
      .ARTICULO::after{
        content: 'ART';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }
      .PARRAFO{
      }
      .paragraph_body_note{
        font-size: .7em;
        color: $grey;
        font-weight: 500;
      }
      .paragraph_body_note::after{
        content: 'NCP';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
      }

      .FIRMA{
        font-size: .7em;
        color: $grey;
        font-weight: 800;
      }
      .FIRMA::after{
        content: 'FIRMA';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
        font-weight: 800;
      }

      // .ANEXO{}
      .DISPOSICION_ADICIONAL,.DISPOSICION_TRANSITORIA,.DISPOSICION_DEROGATORIA,.DISPOSICION_FINAL{
        font-size: 1em;
        font-weight: 700;
      }
      .DISPOSICION_ADICIONAL::after{
        content: 'DISP_AD';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
        font-weight: 800;
      }
      .DISPOSICION_TRANSITORIA::after{
        content: 'DISP_TR';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
        font-weight: 800;
      }
      .DISPOSICION_DEROGATORIA::after{
        content: 'DISP_DE';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
        font-weight: 800;
      }
      .DISPOSICION_FINAL::after{
        content: 'DISP_FI';
        font-size: 9px;
        color: var(--accent);
        margin-left: 10px;
        font-weight: 800;
      }
    }

    #wysiwyg_body_html{
      min-height: 300px;
    }


    .ml_dropdown-click{
      position: relative;
      display: inline-block;
      vertical-align: top;

      .ml_dropbtn {
        align-items: center;
        border: none;
        cursor: pointer;
        padding: 5px;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        padding-top: 0px;
      }

      .ml_dropbtn_img{
        height: 30px;
        width: 40px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_display.svg');
        background-repeat: no-repeat;
        display: inline-block;
        background-size: cover;
        @include shadow;
        border-radius: 5px;
        background-position: 50%;

      }

      .ml_dropbtn_index_img{
        height: 30px;
        width: 40px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_index_display.svg');
        background-repeat: no-repeat;
        display: inline-block;
        background-size: cover;
        @include shadow;
        border-radius: 5px;
        background-position: 50%;
      }

      .ml_dropdown-content{
        @include shadow;
        padding: 10px;
        text-align: center;
        border-radius: 10px;
        min-width: 182px;
        position: absolute;
        z-index: 1;
        background-color: $white;

        p{
          margin: 5px auto;
          cursor: pointer;
        }
        p:hover{
          color:var(--primary);
        }

        .ml_op_active{
          font-weight: 700;
        }


      }

    }

    .ml_spell_check_wrap{
      margin-left: 30px;
    }

    .ml-wysiwyg-icon{
      position: absolute;
      @include shadow;
      text-align: center;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;

      span {
        pointer-events: none;
      }



    }

    .ml_code_icon_wrap{
      margin-left: 10px;

      .ml_code_icon{
        content: "";
        display: block;
        width: 40px;
        height: 30px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/code.svg');
        background-size: cover;
        background-position: 50% ;
      }
    }
    .ml_view_mode{
      margin-right: 30px;
      margin-left: 10px;
    }

    .ml_preview_icon_wrap{
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;

      p {
        pointer-events: none;
      }
      .ml_preview_btn{
        vertical-align: middle;
      }
      .ml_preview_btn::before{
        content: "";
        display: inline-block;
        width: 40px;
        height: 30px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/ver.svg');
        background-size: cover;
        background-position: 50% ;
        vertical-align: middle;
      }
    }

    .ml_preview_icon_wrap:hover{
      @include shadow;
      border-radius: 5px;
    }
    .ml_edit_mode_icon_wrap{
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;

      p {
        pointer-events: none;
      }

      .ml_edit_mode_btn{
        vertical-align: middle;
      }
      .ml_edit_mode_btn::before{
        content: "";
        display: inline-block;
        width: 40px;
        height: 30px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/lapiz.svg');
        background-size: cover;
        background-position: 50% ;
        vertical-align: middle;
      }
    }

    .ml_edit_mode_icon_wrap:hover{
      @include shadow;
      border-radius: 5px;
    }
  }

  .ml_kb_main_down_wrap{
    width: 100%;
    height: 10vh;
    display: block;
    position: relative;
  }


  .ml_bottom_btn_wrap {
    width: 100%;
    height: 100%;
    @include shadow;
    background-color: #fff;
    padding: 10px 0px 20px 0px;
    text-align: center;

    div{
      display: inline-block;
    }

    .ml_btn-alert-wrap{
      text-align: center;
      width: 50%;
      display: block;
    }
    .btn_forAdmin{
      position: absolute;
      top: 10px;
      right: 20px;
    }


  }

  .ml-wysiwyg-help{
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
    p{
      display: inline-block;
      pointer-events: none;
    }

  }
  .ml-wysiwyg-help::before{
    content: "";
    display: inline-block;
    width: 40px;
    height: 30px;
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/ayuda.svg');
    background-size: cover;
    background-position: 50% ;
    vertical-align: middle;
  }

  .ml-wysiwyg-help-modal {
    background-color: $inputblue;
    height: 75%;
    width: 50%;
    border-radius: $radius;
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 90px;
    left: 8px;
    border-radius: 20px;

    .ml_close{
      background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position:50% 50%;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .mq-help-body{
      box-sizing: border-box;
      height: 90%;
      overflow-y: auto;
    }

  }

  .ml-wysiwyg_toolbox{
    position: absolute;
    border-radius: 20px;
    @include shadow;
    height: 40px;
    padding: 10px;
    top: 250px;
    background-color: $white;
    z-index: 99;

    .ml_menu_toolbox.i_container{
      display: inline-block;
    }
    .ml_menu_toolbox{
      display: inline-block;
      vertical-align: middle;
    }

    .ml-options{
      max-height: 250px;
      height: auto;
      overflow-y: scroll;
    }

    .i_container{
      background-color:var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 3px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin: 5px;

      i {
        color: $dark_gray;
        pointer-events: none;
      }
      span{
        display: block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: 200%;
        background-position: 50%;
        pointer-events: none;

      }

      &.ml-icon-btn-left{
        span{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
          transform: rotate(90deg);
        }
      }


      &.ml-icon-btn-right{
        span{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
          transform: rotate(270deg);
        }
      }

      &.ml-icon-btn-left:hover{
        background-color: $white;
        @include shadow;
        span{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
        }
      }


      &.ml-icon-btn-right:hover{
        background-color: $white;
        @include shadow;
        span{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
        }
      }

    }

    .ml_i_container_off{
      background-color:$inputblue;

      i {
        color: $black;
      }
      &:hover > i {
        color: black;
        font-weight: bold;
      }

      &.ml-icon-btn-left{
        height: 55px;
        span{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
          transform: rotate(90deg);
        }
      }

      &.ml-icon-btn-right{
        span{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
          transform: rotate(270deg);
        }
      }
    }
  }
}




// old styles






.i_hover {
  background-color: $light_background;
  border-radius: $radius;
  &:hover {
    color: black;
    font-weight: bold;
    border: 1px solid $text_color;
  }
}

.mq-select{
  background-color: $light_background;
  width: 13vw;
}

.mq-wysiwyg-search-btn{
  width: 40px;
  height: 40px;
  background: $text_color;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i{
    color: $light_background;
    pointer-events: none;
  }
  &:hover {
    background-color: #7d7d7d;
    i {
      font-weight: bold;
    }
  }

  .node {
    padding: 2px;
  }

  .p_selected {
    border: 1px solid var(--primary);
    border-radius: 20px;
  }

}


@media (max-width: 1200px){
  .ml_manual_kb_creator{
    .ml_l_header{
      width: 50%!important;
      box-shadow: none!important;
      padding: 0px!important;
      border-radius: 0px!important;

    }
    .ml_r_header{
      width: 45%!important;
      box-shadow: none!important;
      padding: 0px!important;
      border-left:1px solid var(--primary)!important;
      padding-left: 20px!important;
      border-radius: 0px!important;
    }

    .ml_header_status_wrap{
      box-shadow: none!important;
      padding: 0px!important;
      border-top:1px solid var(--primary)!important;
      padding-top: 20px!important;
      border-radius: 0px!important;
    }


  }
}

.ml_search_result{
  display: inline-bock;
  cursor: pointer;
  color: $ldark;
  position: absolute;
  top: 0px;
  height: 36px;
  background-color:#f1fcfc;
  padding-left: 10px;
  vertical-align: top;
  padding-right: 20px;
  border-radius: 20px;
  right: 0;


  .ml_search_result_count{
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }

  .ml-search_result_icon_clean{
    display: inline-block;
    width: 15px;
    height: 34px;
    background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg);
    background-repeat: no-repeat;
    background-size: 250%;
    background-position: 50% 50%;
  }
  .ml-search_result_icon_clean:hover{
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');

  }
}

.ml-search_result_icons_wrap{
  border-left: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  padding: 0px 5px;
  margin: 0px 5px;
  display: inline-block;
  vertical-align: top;

  .ml-search_result_icons_wrap{
    display: inline-block;
  }

  .ml-search_result_icon_up{
    width: 15px;
    height: 15px;
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
    transform: rotate(180deg);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 50% 50%;
    display: block;
  }
  .ml-search_result_icon_down{
    width: 15px;
    height: 15px;
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 50% 50%;
    display: block;
  }

  .ml-search_result_icon_up:hover{
    width: 15px;
    height: 15px;
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
    transform: rotate(180deg);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 50% 50%;
    display: block;
  }
  .ml-search_result_icon_down:hover{
    width: 15px;
    height: 15px;
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 50% 50%;
    display: block;
  }
}

.ml_disable_action {
  pointer-events: none;
}


@media (max-height: 1000px){
  .ml_manual_kb_creator{ 
    .ml_data_base_container{
      .ml_wysiwyg-body{
        height: 50vh!important;
      }
    } 

  }
}


@media (max-height: 770px){
  .ml_manual_kb_creator{ 
    .ml_header{
      .ml_l_header{
        margin-top: 20px;
        .ml_headline-3{
          display: inline-block!important;
          padding: 0px!important;
          margin: 0px!important;
          vertical-align: top!important;
          margin-top: 10px!important;
          padding-left: 20px!important;
        }
        img{
          display: inline-block!important;
          position: relative!important;
          top:0px;
          right: 0px;
          margin-left: 20px;
        }
        box-shadow:none!important;
        padding: 5px;
      }
      .ml_r_header{
        margin-top: 20px;
        box-shadow:none!important;
        padding: 5px;
      }

    }

    .ml_header_status_wrap{
      box-shadow:none!important;
      padding: 0px 20px!important;
    }

    .ml_data_base_container{
      box-shadow:none!important;
      .ml_wysiwyg-body{
        height: 50vh!important;
        margin-top: 20px!important;
      }
      .ml_code_icon_wrap{
        margin-top: -20px!important;
      }
    } 

    .ml_kb_main_down_wrap{
      height: 50px!important;
      position: absolute!important;
      // bottom: -10px!important;
    }

  }
}

