:root{
    --primary:#38E7ED;
    --lprimary:#7DF7FB;
    --primary2: #AFF9F7;
    --primary3: #CDF7F4;
    --primary4: #D9F9F9;
    --primary5: #F1FCFD;

    --secondary:#8070E8;
    --lsecondary:#807CE8;
    --secondary2: #A3A3EF;
    --secondary3: #B6B6F2;
    --secondary4: #D0D0F4;
    --secondary5: #E5E5F9;

    --accent:#ED4ADD;
    --laccent:#F683EA;
    --accent2: #F4A2F0;
    --accent3: #F0B6F2;
    --accent4: #F2CEF4;
    --accent5: #F9E4F8;
    // opacity
    --accent_op50:#ed4add79;

    --warning:#E8ED27;
    --lwarning:#F4F84F;
    --warning2: #F9F97D;
    --warning3: #F4F0A2;
    --warning4: #F4F3C1;
    --warning5: #F9F9D7;

    --success:#90F7CE;
    --lsuccess:#ABF5DB;
    --success2: #BCF7E1;
    --success3: #CBF7E6;
    --success4: #DCF9EC;
    --success5: #EFF9F5;

    --dark: #0A2327;
    --ldark: #01393A;

    --white: #FFFFFF;

    --grey: #707070;
    --lgrey: #CCCCCC;
    --dgrey: #416B6B;
    --phgrey: #809C9C;
    --not-selected: #DDFEFF;
}
