.mq-input-group {
  position: relative;
}

.mq-input {
  border-radius: $radius;
  border: $border;
  background-color: white;
  height: 45px;
  line-height: 45px;
  padding: 0 40px 0 10px;
  width: -webkit-fill-available;
  transition: .3s ease all;
  &:focus {
    border: 2px solid $dark_gray;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }
}

.mq-input-icon {
  position: absolute;
  right: 10px;
  bottom: 15px;
  z-index: 100;
}

.input-error {
  border: 2px solid $error_color;
  &:focus {
    outline-color: $error_color;
  }
};
