
// Bases de conocimiento / edición

@for $i from 0 through 30 {
    .law-ml-#{$i}{
        width: 100% - $i * 2%;
    }
}

.law_repealed{ color: var(--accent) }

.law_main_title,
.law_book,
.law_title,
.law_chapter,
.law_section,
.law_article,
.law_paragraph,
.law_repeal_provision,
.law_annexed,
.law_temary,
.law_theme,
.law_repealed
{
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 0px;
}

.law_main_title,
.law_book,
.law_title,
.law_chapter,
.law_section,
.law_article,
.law_title_note,
.law_add_privision,
.law_transitional_provision,
.law_repeal_provision,
.law_final_provision,
.law_subsection,
.law_preamble,
.law_sign,
.law_block,
.law_annexed,
.law_temary,
.law_theme,
.law_epigraph,
.law_sub_epigraph,
.law_pull_apart,
.law_sub_pull_apart
{
    font-weight: bold;
}

.law_body_note,
.law_author_paragraph
{
    font-style: italic;
}

.law_main_title, .law_book, .law_title {
    font-size: 1.2em;
}

.law_chapter, .law_section, .law_article {
    font-size: 1em;
}

// Texto de referencia

blockquote{
    background-color:rgba($color: var(--lwarning), $alpha: .5);
}


#scrap_ref_content{
    p{
        margin-top: 5px;
        margin-bottom: 5px;
    }
}


// Programas y prefabs / edición

// Root Programa  -> ml_item-root_prg

// Título Programa
.ml_item-title{
    font-size: 1em;
    color: $ldark;

    span{
        font-weight: 800;
        color: $dark;
    }

    .js_node_editable{
        font-weight: 400;
        display: block;
        margin-top: 10px;
    }
}

// Bloque
.ml_item-block{
    font-size: 1em;
    font-weight: 700;

    span{
        font-weight: 800;
        color: $dark;
    }

    .js_node_editable{
        font-weight: 400;
        display: block;
        margin-top: 10px;
        max-width: 95%;
    }
}

// Tema
.ml_item-theme{
    font-weight: 700;
    font-size: 1em;

    span{
        font-weight: 800;
        color: $dark;
    }

    .js_node_editable{
        font-weight: 400;
        display: block;
        margin-top: 10px;
        max-width: 95%;
    }
}

// Sequencial numérico
.ml_item-numeric{
    .js_span_type{
        font-weight: 700;
        vertical-align: top;
    }
    .js_span_type::after{
        content: ". ";
    }
    .js_node_editable{
        display: inline-block;
        max-width: 95%;
    }
}

.ml_prefab_item{
    .js_span_type{
        font-weight: 700;
        vertical-align: top;
    }
    .js_span_type::after{
        content: ". ";
    }
    .js_node_editable{
        display: inline-block;
        max-width: 95%;
    }
}

