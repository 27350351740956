.ml_loading_icon{
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 40px;
  height: 40px;
}
