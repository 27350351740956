.modal-objs-to-publish{
    .table-objs{
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: 40vh;
        overflow: auto;

        .center-loading{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .table-row{
            display: flex;
            gap: 25px;
            align-items: center;

            .obj-id{ width: 20px; }
            .obj-name{ 
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                min-width: 150px;
            }
            .obj-editor{ width: 150px; }
            .obj-type{ width: 60px; }

            .obj-action{
                width: 60px;
                .assign-icon{
                    width: 40px;
                    height: 40px;
                    display: block;
                    cursor: pointer;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/lapiz.svg');
                    &:hover{ transform: scale(1.1); }
                }
            }
        }
    }
}

%opt_menu{
    position: absolute;
    background-color: white;
    @include shadow;
    padding: 15px;
    border-radius: 10px;
    background-color:$white;

    .ml_link_disabled{
        color: $lgrey;
        pointer-events: none;
    }

    p{
        padding: 5px 0px;
        cursor: pointer;
    }

    p:hover{
        font-weight: 700;
    }
}

.ml_program_prefab_wrap{
    width: 100%;
    height: 100vh;
    overflow-y: hidden;

    .ml-modal-bg{
        background-color: #01393a96;
        position: absolute;
        height: 100vh;
        z-index: 110;
        width: 100vw;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .table-editor-to-assign{
            display: flex;
            flex-direction: column;
            gap: 15px;

            .center-loading{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            #editor-list-to-assign{
                height: 40vh;
                overflow: auto;
            }

            .table-row{
                display: flex;
                gap: 10px;
                align-items: center;

                .editor-name{ width: 80%; }

                .editor-action{
                    .assign-icon{
                        width: 40px;
                        height: 40px;
                        display: block;
                        cursor: pointer;
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/bien.svg');
                        &:hover{ transform: scale(1.1); }
                    }
                }
            }

        }

        .ml_close{
            background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: 200%;
            background-position:50% 50%;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .ml-modal-content-wrap{
            padding: 20px 40px;

            .ml-modal-content-left{
                width: 49%;
                display: inline-block;

                p{
                    padding-bottom: 10px;
                }
                input{
                    margin-bottom: 10px;
                }
    
                .ml_btn-wrap{
                    padding-top: 20px;
                    padding-left: 0px;
                    margin-left: 0px;
                    padding-right: 70px;
                    padding-bottom: 20px;
                }
                .ml_select_wrap{
                    width: 180px;
                }
                .ml_input_wrap input{
                    width: 60%;
                }
            }

            .ml-modal-content-right{
                width: 49%;
                display: inline-block;
                vertical-align: top;

                .ml_paragraph{
                    margin-bottom: 15px;
                }

                .ml-modal-content-scroll{
                    height: 90%;
                    max-height: 300px;
                    overflow:scroll;
                    .ml-modal-content-programs-asoc{
                        height: auto;
                        p{
                            padding: 5px 0px;
                        }
                    }
                }
            }

            .ml_btns-wrap{
                margin: auto;
                display: block;
                div{
                    display: inline-block;
                    padding-right: 10px;
                }
            }
        }

        .ml_close:hover{
            background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
        }

        .ml-modal-card{
            background-color: white;
            min-height: 40%;
            max-height: 80%;
            border-radius: 3px;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 60%;
            min-width: 300px;
        }

        .ml-modal-card-sm{
            background-color: white;
            min-height: 10%;
            max-height: 80%;
            border-radius: 3px;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 40%;
            min-width: 300px;
        }
    }

    .modal_preview_tree{
        overflow:hidden;
        .ml-modal-content-wrap{
            overflow: scroll ;
        }
    }

    .ml_left_wrap{
        width: 49%;
        padding-left: 10px;
        display: inline-block;
        height: 100vh;
        vertical-align: top;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        

        .ml_l_header{
            width: 95%;
            padding: 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;

            .big-title-content{
                position: relative;
                &:after{
                    content: attr(data-value);
                    width: 90%;
                    background-color: white;
                    position: absolute;
                    top: 30px;
                    left: 0px;
                    opacity: 0;
                    padding: 15px 25px;
                    border-radius: 20px;
                    z-index: 1;
                    transition: opacity .5s ease-in-out;
                    @include shadow;
                }
                &:hover:after{
                    opacity: 1;
                }
            }

            p{
                &.ml_headline-3{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    position: relative;
                }
                max-width: 90%;
            }

            img{
                width: 50px;
                position: absolute;
                top:20px;
                right: 20px;
                cursor: pointer;
                @include shadow;
                    border-radius: 10px;
            }
            .ml_headline-3{
                margin-top: .5em;
                margin-bottom: 5px;
            }
            .ml_headline-type{
                margin-bottom: .5em;
            }
        }

        .ml_l_sub_header{
            display: flex;
            gap: 10px;
            align-items: center;
            position: relative;
            width: 95%;
            padding: 20px 20px 20px 20px;
            border-radius: 20px;
            margin-top: 10px;
            @include shadow;

            .state_col{
                display: flex;
                align-items: center;
                flex: .35;
                .dot{
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    margin: 0px 10px;
                    &_draft{ background-color: var(--warning); }
                    &_published{ background-color: var(--success); }
                }
            }
            
            .btn_col{
                display: flex;
                align-items: center;
                flex: .65;
                gap: 10px;
            }

            .menu_display_pr_pf,
            .btn_preview_tree,
            .ml_open_close_button,
            .ml_refresh_button {
                height: 30px;
                width: 40px;
                background-repeat: no-repeat;
                background-position: 50%;
                cursor: pointer;
                border-radius: 5px;
                @include shadow;
            }

            .menu_display_pr_pf{
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_display.svg');
                background-size: cover;
            }

            .btn_preview_tree{
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/arbol_temas.svg');
                background-size: 70%;
            }
            .ml_refresh_button{
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/actualizar.svg);
                width: 30px;
                &:hover{ transform: scale(1.1); }
            }

            .ml_open_close_button{
                width: 30px;
                height: 30px;
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg);
                transform: rotate(90deg);
                background-position: 50% 50%;
                background-color: #ffffff;
                background-size: 150%;
            }
            .leaf_counter{
                display: flex;
                gap: 5px;
                align-items: center;
                > div{
                    display: flex;
                    flex-direction: column;
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    @include shadow;

                    .up, .down{
                        background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg);
                        width: 30px;
                        height: 15px;
                        cursor: pointer;
                        background-position: 50% 50%;
                        background-size: 140%;
                    }

                    .up{
                        transform: rotate(180deg);
                        &:hover{ transform: rotate(180deg) scale(1.2); }
                    }
                    .down:hover{ transform: scale(1.2); }
                }
            }
        }

        .ml_l_create_content_wrap{
            width: 95%;
            height: 66%;
            padding: 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;
            margin-top: 10px;
            overflow-y: scroll;
            div[data-isLeaf="true"]{
                > div{ border-left: 10px solid var(--accent); }
            }

            .ml_button_add_node,.ml_button_add_node_main{
                display: block;
                width: 30px;
                height: 30px;
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/add_bold.svg');
                background-repeat: no-repeat;
                margin: 20px;
                border-radius: 15px;
                @include shadow;
                background-size: 70%;
                background-position-x: 50%;
                background-position-y: 50%;
                cursor: pointer;
                background-color:$white;
            }
           
            .ml_node_wrap{
                // margin-bottom: 5px;
                cursor: pointer;

                .ml_button_add_node{
                    display: none;
                }

                .ml_node_wrap_box_shadow{
                    padding: 15px;
                    position: relative;
                    background-color:$white;
                }

                .ml_node_wrap_box_shadow.ml_nosaved{
                    background-color:$inputblue;
                }

                .ml_node_wrap_box_shadow.ml_error_saved{
                    background-color: var(--accent4);
                }

                .ml_node_wrap_box_shadow.ml_prefab_item{
                    background-color: var(--warning5);
                }

                .ml_node_wrap_box_shadow.ml_kb_item{
                    // background-color: $lgrey;
                    color: $grey;
                    padding: 5px;
                    border-radius: 5px;
                    font-weight: 700;
                }

                .ml_node_icons_wrap{
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    // background-color:$white;
                }
                .ml_node_premium{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/premium.svg');
                    border-radius: 10px;
                    background-repeat: no-repeat;
                    background-size:cover;
                    // background-color:$white;
                }
                .ml_node_demo{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/demo.svg');
                    border-radius: 10px;
                    background-repeat: no-repeat;
                    background-size:cover;
                    // background-color:$white;
                }

                .ml_node_stop{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/stop.svg');
                    border-radius: 10px;
                    background-repeat: no-repeat;
                    background-size: 30px;
                    // background-color:$white;

                    div{
                        color: salmon;
                    }
                }
            }

            .ml_node_wrap:not(.no_hover):hover{
                padding: 0px;

                .ml_button_add_node{
                    display: block;
                }

                .ml_node_wrap_box_shadow{
                    @include shadow;
                    border-radius:15px;
                }
            }

            .ml_node_wrap:not(.no_hover):hover ~ .ml_button_add_node_main{
                display: none;
            }
           
            .ml_item_selected{
                .ml_button_add_node{
                    display: block;
                }

                .ml_node_wrap_box_shadow{
                    @include shadow;
                    border-radius:15px;
                }

                .ml_button_add_node_out{
                    display: none;
                }
            }

            .ml_toolbox{
                @include shadow;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 5px;
                position: fixed;
                background: white;
                display: flex;
                background-color:$white;
                z-index: 99;

                .ml_select_wrap{
                    display: inline-block;
                    padding: 0 10px;
                    margin: 0px;
                }

                .ml_remove_link{
                    width: 40px;
                    height: 72px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/no-link.svg');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    border-left: 1px solid var(--primary);
                    cursor: pointer;
                }
                .ml_more_options{
                    width: 40px;
                    height: 72px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu-puntos.svg');
                    background-size: 150%;
                    background-position: 50% 50%;
                    border-left: 1px solid var(--primary);
                    cursor: pointer;
                }
                .ml_more_options_wrap{
                    top:90px;
                    right: -94px;
                    @extend %opt_menu;

                    .ml_menu_divisor{
                        width: 90%;
                        height: 2px;
                        background-color: var(--primary);
                        display: block;
                        margin: 10px auto;                        
                    }
                }
                .ml_spfo_options_wrap{
                    bottom: -145px;
                    right: 70px;
                    width: 300px;
                    @extend %opt_menu;
                }
            }

            .ml_i_container{
                background-color:var(--primary);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                border-radius: 3px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin: 5px;

                &.ml-icon-btn-up:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
    
                    }
                    
                }
    
                &.ml-icon-btn-down:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                    
                }
                
                &.ml-icon-btn-left:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                    
                }
    
              
                &.ml-icon-btn-right:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                    
                }
    
                &.ml-icon-btn-forward:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg');
                    }
                    
                }
    
    
                &.ml-icon-btn-to-top:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
                    }
                    
                }
               
                &.ml-icon-btn-to-bottom:hover{
                    background-color: $white;
                    @include shadow;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
                    }
                    
                }
            }

            .ml-icon-btn-up{
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
                    transform: rotate(180deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }
           
            .ml-icon-btn-down{
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }
          
            .ml-icon-btn-left{
                height: 55px;
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
                    transform: rotate(90deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }

            .ml-icon-btn-right{
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
                    transform: rotate(270deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }
           
            .ml-icon-btn-forward{
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/doble-flecha.svg');
                    transform: rotate(270deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }
          
            .ml-icon-btn-to-top{
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_con-tope.svg');
                    transform: rotate(180deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }
           
            .ml-icon-btn-to-bottom{
                span{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_con-tope.svg');
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50%;
                    pointer-events: none;
                }
            }

            .ml_i_container_off, .ml_i_container_off:hover{
                background-color: $inputblue;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                border-radius: 3px;
                cursor:inherit;
                width: 20px;
                height: 20px;
                margin: 5px;

                &.ml-icon-btn-up{
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                }
               
                &.ml-icon-btn-down{
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                }
              
                &.ml-icon-btn-left{
                    height: 55px;
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                
                }
    
    
                &.ml-icon-btn-right{
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    }
                }
               
    
                &.ml-icon-btn-forward{
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg');
                    }
                }
              
                &.ml-icon-btn-to-top{
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
                    }
                }
               
                &.ml-icon-btn-to-bottom{
                    span{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
                    }
                }
            }
        }
    }
    
    .ml_left_show_full{ width: 100%; }

    .ml_right_wrap{
        width: 48%;
        display: inline-block;
        height: 100vh;
        margin-left: 20px;
        position: relative;

        .ml_open_close_button{
            background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/ver.svg');
            width: 40px;
            height: 30px;
            cursor: pointer;
            @include shadow;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            position: absolute;
            top: 15px;
            right: 10px;
        }

        -webkit-transition: width 1.5s ease-in-out;
        -moz-transition: width 1.5s ease-in-out;
        -o-transition: width 1.5s ease-in-out;
        transition: width 1.5s ease-in-out;


        .ml_r_header{
            width: 95%;
            padding: 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;

            .ml_input-prpf-wrap{
                display: inline-block;
                margin-right: 24px;
                div{
                    display: inline-block;
                }
                
            }

            form{
                display: inline-block;
                width: 350px;

                .ml_input-psw-icon-wrap{
                    width: 100%;
                }

                input{
                    font-size: 12px;
                    width: 70%;
                    margin-left: 0px;
                }
            }

            .ml_paragraph{
                display: inline-block;
            }

            .ml_btn-case-sensitive{
                color: var(--primary);
                padding: 10px;
                border-radius: 5px;
                background-color: white;
                @include shadow;
                cursor: pointer;
                margin-left: 20px;
                margin-right: 20px;

                &-on{
                    color: $white;
                    padding: 10px;
                    border-radius: 5px;
                    background-color:var(--primary);
                    cursor: pointer;
                }
            }

            .ml_btn-case-sensitive:hover{
                color: $white;
                padding: 10px;
                border-radius: 5px;
                background-color:var(--primary);
                cursor: pointer;
            }

            .ml_btn-case-sensitive-on:hover{
                color: var(--primary);
                padding: 10px;
                border-radius: 5px;
                background-color: white;
                @include shadow;
                cursor: pointer;
            }
            

            .ml_search_result{
                display: inline-bock;
                cursor: pointer;
                color: $ldark;
                position: absolute;
                top: 0px;
                height: 36px;
                background-color:#f1fcfc;
                padding-left: 10px;
                vertical-align: top;
                padding-right: 20px;
                border-radius: 20px;
                right: 0;
    
                
                .ml_search_result_count{
                    display: inline-block;
                    height: 40px;
                    vertical-align: middle;
                }
    
                .ml-search_result_icon_clean{
                    display: inline-block;
                    width: 15px;
                    height: 34px;
                    background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg);
                    background-repeat: no-repeat;
                    background-size: 250%;
                    background-position: 50% 50%;
                }
                .ml-search_result_icon_clean:hover{
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
    
                }
            }
    
            .ml-search_result_icons_wrap{
                border-left: 1px solid var(--primary);
                border-right: 1px solid var(--primary);
                padding: 0px 5px;
                margin: 0px 5px;
                display: inline-block;
                vertical-align: top;
    
                .ml-search_result_icons_wrap{
                    display: inline-block;
                }
    
                .ml-search_result_icon_up{
                    width: 15px;
                    height: 15px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    transform: rotate(180deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50% 50%;
                    display: block;
                }
                .ml-search_result_icon_down{
                    width: 15px;
                    height: 15px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50% 50%;
                    display: block;
                }
    
                .ml-search_result_icon_up:hover{
                    width: 15px;
                    height: 15px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
                    transform: rotate(180deg);
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50% 50%;
                    display: block;
                }
                .ml-search_result_icon_down:hover{
                    width: 15px;
                    height: 15px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
                    background-repeat: no-repeat;
                    background-size: 200%;
                    background-position: 50% 50%;
                    display: block;
                }
    
                
    
            }

            .ml-search_result_icon_clean{
                display: inline-block;
                width: 15px;
                height: 34px;
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
                background-repeat: no-repeat;
                background-size: 250%;
                background-position: 50% 50%;
            }

            .ml-search_result_icon_clean:hover{
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');

            }

            

        }

        .ml_r_sub_header{
            width: 95%;
            padding: 5px 20px 20px 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;

            .mk_paragraph{
                display: inline-block;
            }

            .ml_right_options_button{
                height: 30px;
                width: 40px;
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_hamb.svg);
                background-repeat: no-repeat;
                display: inline-block;
                background-size: cover;
                cursor: pointer;
                @include shadow;
                border-radius: 5px;
                background-position: 50%;    
                margin-top: 15px;     
                margin-right: 15px;   
            }

            .ml_dropdown-content{
                @include shadow;
                padding: 10px 20px;
                border-radius: 10px;
                min-width: 182px;
                position: absolute;
                z-index: 11;
                background-color: #ffffff;

                p{
                    margin: 5px auto;
                    cursor: pointer;
                }
                p:hover{
                    color:var(--primary);
                }

                .ml_op_active{
                    font-weight: 700;
                }
            }

            .ml_card-h{
                display: inline-block;
                vertical-align: bottom;
            }

            .ml_cross_line{
                background-color: var(--primary2);
                width: 98%;
                margin: auto;
                text-align: center;
                height: 1px;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .ml_list_search_wrap{
                margin-top: 10px;
                position: relative;

                
                form, div{
                    display: inline-block;
                    button{
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
                input{
                    margin-left: 0px;
                }


                .ml_search_prompt{
                    @include shadow;
                    padding: 15px;
                    text-align: left;
                    border-radius: 10px;
                    min-width: 182px;
                    position: absolute;
                    z-index: 1;
                    background-color: #ffffff;
                    top: 40px;
                    left: 10px;
    
                    p{
                        margin: 5px auto;
                        cursor: pointer;
                    }
                    p:hover{
                        color:var(--primary);
                    }
                }

            }

            .ml_inside_search_wrap{
                margin-top: 10px;
                position: relative;

                form{
                    display: inline-block;
                    width: 500px;
    
                    .ml_input-psw-icon-wrap{
                        width: 70%;
                    }
    
                    input{
                        font-size: 12px;
                        width: 70%;
                        margin-left: 0px;
                    }
                }
    
                .ml_paragraph{
                    display: inline-block;
                }
    
                .ml_btn-case-sensitive{
                    color: var(--primary);
                    padding: 10px;
                    border-radius: 5px;
                    background-color: white;
                    @include shadow;
                    cursor: pointer;
                    margin-left: 20px;
                    margin-right: 20px;
                    strong{
                        pointer-events: none;
                    }

                    &-on{
                        color: $white;
                        padding: 10px;
                        border-radius: 5px;
                        background-color:var(--primary);
                        cursor: pointer;
                    }
                }
    
                .i_active{
                    background-color: var(--primary);
                    color: $white;
                }

                .ml_btn-case-sensitive:hover{
                    color: $white;
                    padding: 10px;
                    border-radius: 5px;
                    background-color:var(--primary);
                    cursor: pointer;
                }
    
                .ml_btn-case-sensitive-on:hover{
                    color: var(--primary);
                    padding: 10px;
                    border-radius: 5px;
                    background-color: white;
                    @include shadow;
                    cursor: pointer;
                }
                
    
                .ml_search_result{
                    display: inline-bock;
                    cursor: pointer;
                    color: $ldark;
                    position: absolute;
                    top: 0px;
                    height: 36px;
                    background-color:#f1fcfc;
                    padding-left: 10px;
                    vertical-align: top;
                    padding-right: 20px;
                    border-radius: 20px;
                    right: 0;
        
                    
                    .ml_search_result_count{
                        display: inline-block;
                        height: 40px;
                        vertical-align: middle;
                    }
        
                    .ml-search_result_icon_clean{
                        display: inline-block;
                        width: 15px;
                        height: 34px;
                        background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg);
                        background-repeat: no-repeat;
                        background-size: 250%;
                        background-position: 50% 50%;
                    }
                    .ml-search_result_icon_clean:hover{
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
        
                    }
                }
        
                .ml-search_result_icons_wrap{
                    border-left: 1px solid var(--primary);
                    border-right: 1px solid var(--primary);
                    padding: 0px 5px;
                    margin: 0px 5px;
                    display: inline-block;
                    vertical-align: top;
        
                    .ml-search_result_icons_wrap{
                        display: inline-block;
                    }
        
                    .ml-search_result_icon_up{
                        width: 15px;
                        height: 15px;
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                        transform: rotate(180deg);
                        background-repeat: no-repeat;
                        background-size: 200%;
                        background-position: 50% 50%;
                        display: block;
                    }
                    .ml-search_result_icon_down{
                        width: 15px;
                        height: 15px;
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                        background-repeat: no-repeat;
                        background-size: 200%;
                        background-position: 50% 50%;
                        display: block;
                    }
        
                    .ml-search_result_icon_up:hover{
                        width: 15px;
                        height: 15px;
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
                        transform: rotate(180deg);
                        background-repeat: no-repeat;
                        background-size: 200%;
                        background-position: 50% 50%;
                        display: block;
                    }
                    .ml-search_result_icon_down:hover{
                        width: 15px;
                        height: 15px;
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
                        background-repeat: no-repeat;
                        background-size: 200%;
                        background-position: 50% 50%;
                        display: block;
                    }
        
                    
        
                }
    
                .ml-search_result_icon_clean{
                    display: inline-block;
                    width: 15px;
                    height: 34px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
                    background-repeat: no-repeat;
                    background-size: 250%;
                    background-position: 50% 50%;
                }
    
                .ml-search_result_icon_clean:hover{
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
    
                }
            }

            .ml_check_only_bk{
                margin-top: 10px;

                input{
                    margin-right: 10px;
                }
            }
        }
        

        .ml_r_content_wrap{
            width: 95%;
            padding: 0px 20px 20px 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;
            margin-top: 10px;
            // overflow-y: scroll;
            height: 72%;

            .ml_empty_msg{
                padding-top: 150px;
                text-align: center;
            }

            .ml_right_options_button{
                height: 30px;
                width: 40px;
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_hamb.svg);
                background-repeat: no-repeat;
                display: inline-block;
                background-size: cover;
                cursor: pointer;
                border-radius: 5px;
                background-position: 50%;    
                margin-top: 15px;     
                margin-right: 15px;   
            }

            .ml_dropdown-content{
                @include shadow;
                padding: 10px;
                text-align: center;
                border-radius: 10px;
                min-width: 182px;
                position: absolute;
                z-index: 1;
                background-color: #ffffff;

                p{
                    margin: 5px auto;
                    cursor: pointer;
                }
                p:hover{
                    color:var(--primary);
                }

                .ml_op_active{
                    font-weight: 700;
                }
            }

            .ml_inside_header_view,.ml_list_header_view{
                position: relative;
                background-color: white;
                width: 100%;
                z-index: 99;

                
            }


            .ml_inside_content_wrap{
                //margin-top: 115px;
                overflow-y: scroll;
                position: relative;
                height: 80%;

                .ml_word_not_found{
                    text-align: center;
                    margin-top: 200px;
                }

                .ml_highlightParagraph {
                    border-radius: $radius;
                    background-color: $inputblue;
                }

                .ml_highlightParagraphSelected {
                    padding: 10px 0px;
                    border-radius: $radius;
                    background-color: $inputblue;
                }

                .node{
                    background-color: white;
                    border-radius: 3px;
                    &:hover{
                        padding: 10px 0px;
                        border-radius: $radius;
                        background-color: $inputblue;
                        cursor: pointer;
                    }
                }
                .ml_pf-draggable{
                    background-color: white;
                    border-radius: 3px;
                    &:hover{
                        padding: 10px 0px;
                        border-radius: $radius;
                        background-color: $inputblue;
                        cursor: pointer;
                    }
                }
            }

            .ml_bks_list_wrap,.ml_pf_list_wrap{
                padding-top: 36px;
                height: 69vh;
                overflow-y: auto;
            }
            .ml_bk_pf_name{
                display: inline-block;
                vertical-align: bottom;
            }

            .ml_back_list_mode_btn{
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;
            }

            .ml_back_list_mode_btn:hover{
                font-weight: 700;
            }

            .ml_cross_line{
                background-color: var(--primary2);
                width: 98%;
                margin: auto;
                text-align: center;
                height: 1px;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .ml_bks_list_header_wrap, .ml_pf_list_header_wrap{
                display: flex;
                gap: 5px;
                padding: 0px 10px;

                .ml_input_wrap{ width: 20px; }
                .ml_id{ width: 30px; }
                .ml_name{ flex: 1; }
                .ml_num_of_prog{ width: 60px; }
                .ml_icon{ width: 35px; }
            }

            .ml_bks_list,.ml_pf_list{
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                gap: 11px;

                .ml_bk_row,.ml_pf_row{
                    display: flex;
                    gap: 5px;
                    cursor: pointer;
                    padding: 5px 10px;

                    &:hover{ background-color: rgba(200, 200, 200, .1); }

                    .ml_input_wrap{ width: 20px; }
                    .ml_id{ width: 30px; }
                    .ml_name{ flex: 1; }
                    .ml_num_of_prog{ 
                        width: 60px;
                        text-align: center;
                    }
                    .ml_icon{
                        position: relative;
                        width: 35px;
                        height: 22px;

                        .ml_btn_edit{
                            height: 22px;
                            width: 35px;
                            background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/ver.svg);
                            background-repeat: no-repeat;
                            display: inline-block;
                            background-size: 90%;
                            cursor: pointer;
                            border-radius: 5px;
                            background-position: 50%;    
                            position: absolute;
                            right: 0;
                            top:0;
                        }
                    }
                }
            }
        }
    }

    .ml_right_hide{
        width: 0%;
        margin-right: 100px;
    }

    .ml_r_closed{
        position: absolute;
        top: 0;
        right: 0;
    }

    .ml_bottom_btn_wrap{ // esto está aún por definir:
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0px;
        @include shadow;
        background-color: $white;
        padding: 10px 0px 20px 0px;
        text-align: center;
    }

    .ml_counter{
        margin-bottom: 40px;
        span{
            border: 3px solid var(--primary);
            border-radius: 50px;
            padding: 30px;
        }
    }
    
    .ml_no-saved-example {
        padding: 12px 15px;
        background-color:$inputblue;
    }
    
    .ml_error-saved-example {
        padding: 12px 15px;
        background-color: var(--accent4);
    }
    
    .ml_w-50 { width: 50%; }
    
    .ml_pf-option {
        // display: table;
        width: auto;
        max-width: 300px;
        margin: 0 15px;
        cursor: pointer;
        div{
            text-overflow: ellipsis;
            overflow: hidden;
            width: 250px;
            white-space: nowrap;
            pointer-events: none;
            vertical-align: middle;
            padding: 0;
            padding-right: 10px;
            display: inline-block;
        }
    }

    .ml_pf-option::before{
        content: "";
        background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/link.svg);
        width: 30px;
        height: 30px;
        display: inline-block;
        background-position: 50%;
        vertical-align: bottom;
    }

    .js_node_editable:empty:not(:focus):before{
        content:attr(data-placeholder);
        color: $grey;
    }

    .ml_highlightWord{
        font-weight: bold;
        background-color: var(--warning);
        border-radius: $radius;
        pointer-events: none;
    }
    
    .ml_title_container{
        display: inline;
        .ml_card-h{
            display: table-cell;
            vertical-align: middle;
        }
        .ml_pf_linked{
            display: inline;
            img{
                width: 40px;
            }
            span{
                position: absolute;
                right: 40px;
                vertical-align: middle;
            }
        }
    }
    
    .ml_linked_bg{ background-color: var(--success3) !important; }
    
    .ml_d-inline { display: inline; }
        
    .ml_w-100{ width: 100%; }

    button.disabled{
        pointer-events: none;
        opacity: .4;
    }
}

@media (max-width: 1530px) {
    .ml_program_prefab_wrap{
        .ml_right_wrap{
            .ml_r_content_wrap{
                height: 69%;
            }
        } 
    }
}

.ml_item_disabled{
    pointer-events: none;
    background-color: $lgrey;
    border: $lgrey;
    opacity: .5;
}

.ml_text-center{ text-align: center; }

@for $i from 1 through 10 {
    div[data-level="#{$i}"]{
        margin-left: $i * 20 - 20px;
    }
}

.ml_arbol-contenido{
    height: 400px;
    overflow-y: auto;
}

