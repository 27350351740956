.ml_cms_login_wrap{

	.ml-logo-container{
		margin: auto;
		text-align: center;
		padding-top: 10%;
		margin-bottom: 50px;

		svg{
			width: 200px;;
		}
	}

	.ml-form-container{
		width: 500px;
		margin: auto;
		text-align: center;
	}

	form{
		width: auto;
		text-align: left;
	    /*max-width: 250px;*/
	    margin:auto;
	    width: 90%;
    	max-width: 600px;
    	text-align: center;
		.ml_input-icon-wrap,.ml_btn-wrap{
			display:block;
			margin: 20px auto;
			text-align: center;
			max-width: 270px;

			&-login{
				width: fit-content;
				margin-top: 50px;
			}
		}
	}

	.ml_form_content_wrap{
		width: 300px;
		text-align: left;
		margin: auto;
		.ml_input_form{
			display: inline-block;
			width: 95%;
			margin: 15px 0px;
		}
	}

	#loginBtn span{
		pointer-events: none;
	}

	.ml_btn-secondary{
		&-login{
			width: 95%;	
		}
	}
	.ml_input-cb-wrap{
		margin-top: 20px;
		max-width: 250px;
		text-align: left;
		input{
			margin-left: 7px !important;
		}
	}
	/*.ml_input-icon-wrap{
		max-width: 250px;
	}*/

	.ml_btn-link span{
		width: 107%;
	}

	.ml_tx_error{
		margin-top: 20px;
		/*display: none;*/
	}
}

.ml_cms_login_recover_wrap{
	.ml-logo-container{
		margin-bottom: 10px;
	}
	.ml_btn-link span{
		width: 115%;
	}
	.ml_input-psw-icon-wrap{
		margin-top: 30px;
	}
}
.ml_cms_login_change_wrap{

	width: 80%;

	.ml-logo-container{
		position: absolute;
		top: 20px;
		left: 30px;
		margin: 0px;
		padding: 0px;
		svg{
			width: 130px;
		}
	}
	.ml-form-container{
		max-width: 400px;
	}
	.ml_wrap{
		width: 49%;
		display: inline-block;
		min-height: 100px;
		&_left{
			padding-top: 15%;
			text-align: left;
			vertical-align: top;


			p{
				text-align: left;
			}

			.ml_ul{
		    	margin: 20px auto 30px auto;
			}
		}
		&_right{
			position: relative;
			padding-top: 15%;

			p{
				max-width: 280px;
				margin: auto;
				font-size: .8rem;
			}
			
		}
	}
	form{
		text-align: left;
    	padding-left: 0px;
    	margin-left: 0px;

    	.ml_input-psw-icon-wrap{
    		margin: 10px auto;
    		
    	}
    	input{
    		padding: 10px 80px 10px 30px;
    	}

    	.ml_btn-wrap{
    		text-align: left;
    		margin-left: 0px;
    	}
	}
	.ml_svg_wrap{
		margin:auto;
		text-align: center;
	}
	.ml_bite{
		position: relative;
	}
	.ml_txt_bite{
		position: absolute;
	    width: 100%;
	    text-align: center;
	    top: 50px;

	    .ml_paragraph{
	    	b{
	    		font-size:.9rem;
	    	}
	    	
	    }

	}
	.ml_ul{
		width: 300px;
		margin: auto;
		li{
			font-size: .8rem;
		}
	}
	

}


@media only screen and (max-width: 1300px) {
	.ml_cms_login_change_wrap{
		width: 100%;
	}
}

@media only screen and (max-width: 950px) {
	.ml_wrap{
		display: block !important;
		width: 90% !important;
		    margin: auto;
	}
}
