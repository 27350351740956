.ml_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50vh;
  padding: 10px 15px;
  margin: auto;
  border-radius: 20px;
  @include shadow;

  p {
    padding: 16px 10px;
    text-align: center;
  }
  .ml_btn-secondary{
    span{
      pointer-events: none;
    }
  }
}

