
.ml_kb_main_wrap{
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    font-family: 'Spartan', sans-serif;
    color: $dark;

    .ml_btn-primary{
        &.button-off{
            opacity: .4;
            pointer-events: none;
        }
    }

    .disabled{
        opacity: .4;
        pointer-events: none;
    }

    .text-loading::after {
        content: '';
        width: 10px;
        display: inline-block;
        animation: dots 1.5s steps(4, end) infinite;
    }

    @keyframes dots {

        0%,
        100% {
            content: '';
        }

        25% {
            content: ' .';
        }

        50% {
            content: ' ..';
        }

        75% {
            content: ' ...';
        }
    }

    .ml_kb_main_up_wrap{
        width: 100%;
        height: 10vh;
        margin-bottom: 10px;
        display: block;
        position: relative;
        margin-top: 5px;
    }

    .ml_kb_main_middle_wrap{
        width: 100%;
        height: 68vh;
        margin-bottom: 10px;
        display: block;
        position: relative;
    }

    .ml_kb_main_down_wrap{
        width: 100%;
        height: 10vh;
        display: block;
        position: relative;
    }

    .ml-modal-bg{
        background-color: #01393a96;
        position: absolute;
        height: 100vh;
        z-index: 110;
        width: 100vw;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .ml_close{
            background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: 200%;
            background-position:50% 50%;
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .ml-modal-content-wrap{
            padding: 40px 50px;
            .ml_metadatos, .ml_editor_assign{
                height: auto;
                width: 60vw;
            }
            .ml_editor_assign{
                .ml_left_side, .ml_right_side{
                    width: 49%;
                    display: inline-block;
                }
                .ml_left_side{
                    border-right: 1px solid var(--primary);
                    padding-right: 5px;
                }
                .ml_paragraph-h{
                    padding-left: 40px;
                    margin-bottom: 0px;
                }
                .head_line{
                    height: 1px;
                    width: 100%;
                    background-color: var(--primary);
                }
                .ml_header_list{
                    box-sizing: border-box;
                    padding: 5px 15px;
                    span{
                        display: inline-block;
                        &.ml_head_id{
                            width: 8%;
                        }
                        &.ml_head_name{
                            width: 60%; // must be 40 if permission is enabled
                        }
                        &.ml_head_permission{
                            width: 40%;
                        }
                    }
                }
                .ml_list_container{
                    box-sizing: border-box;
                    padding: 5px 15px;
                    position: relative;
                    height: 39vh;
                    overflow-y: auto;
                    .ml_row{
                        margin: 15px 0px;
                        height: 40px;
                        position: relative;
                        span{
                            display: inline-block;
                            &.ml_paragraph{
                                // padding-top: 10px;
                                font-size: 11px;
                            }
                            &.ml_row_id{
                                width: 8%;
                            }
                            &.ml_row_name{
                                width: 60%; // must be 40 if permission is enabled
                            }
                            &.ml_row_permission{
                                width: 40%;
                                .ml_input_wrap{
                                    display: inline-flex;
                                    margin: 0px 8px;
                                    input, label{
                                        cursor: pointer;
                                    }
                                }
                            }
                            &.ml_add_icon{
                                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/add.svg);
                                width: 40px;
                                height: 40px;
                                position: absolute;
                                top: -12px;
                                cursor: pointer;
                                &:hover{
                                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/mas.svg');
                                }
                            }
                            &.ml_remove_icon{
                                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/tacho_basura.svg);
                                width: 40px;
                                height: 40px;
                                position: absolute;
                                top: -12px;
                                cursor: pointer;
                                &:hover{
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
            }
            .ml_slide_container{
                margin-bottom: 30px;
                .ml_btn-selected{
                    z-index: 50;
                }
                .ml_btn-option{
                    z-index: 90;
                }
            }

            .ml_btn-wrap{
                padding-top: 20px;
                padding-left: 0px;
                margin-left: 0px;
                padding-right: 70px;
                padding-bottom: 20px;
            }

            .ml_select_wrap{
                width: 180px;
            }
            .ml_input_wrap input{
                width: 60%;
            }

            .ml_metadata_wrap{
                width: 100%;

                > div{
                    vertical-align: top;
                    display: inline-block;
                    width: 49%;
                }
            }

            .prg-affected-container{
                max-height: 300px;
                overflow: auto;
                .prg-item{
                    cursor: pointer;
                    p, div{ pointer-events: none; }
                    &:hover{
                        p { font-weight: bold; }
                        div{ transform: scale(1.1) rotate(-90deg); }
                    }
                    .ml_navigate_to_prg{
                        width: 40px;
                        height: 40px;
                        display: block;
                        transform: rotate(-90deg);
                        background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg);
                    }
                }
            }
        }

        .ml_close:hover{
            background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
        }

        .ml-modal-card{
            background-color: white;
            max-height: 80%;
            border-radius: 30px;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: auto;
            min-width: 300px;
            &.sm { width: 35%; }
        }

        .ml_modal_goto_update_panel{
            p{ max-width: 450px; }
        }

        .ml_modal_correct_law{
            p{
                width: 90%;
                max-width: 700px;
            }
            textarea{
                width: 90%;
                max-width: 700px;
                background-color: $inputblue;
                padding: 10px;
                border: 0px;
                border-radius: 10px;
            }
        }

        .ml_modal_report_error{
            p{
                width: 90%;
                max-width: 700px;
            }
            textarea{
                width: 90%;
                max-width: 700px;
                background-color: $inputblue;
                padding: 10px;
                border: 0px;
                border-radius: 10px;
            }
            .ml_btn-wrap{
                display: inline-block;
                padding-right: 15px;
            }
        }

        .ml_modal_validate_law{
            text-align: center;

            .ml_btns-wrap{
                margin: auto;
                div{
                    display: inline-block;
                    padding-right: 10px;
                }
            }
        }

        .ml_modal_revision_law{
            text-align: center;

            .ml_btns-wrap{
                margin: auto;
                div{
                    display: inline-block;
                    padding-right: 10px;
                }
            }
        }

        .ml_modal_return_law{
            p{
                width: 90%;
                max-width: 700px;
            }
            textarea{
                width: 90%;
                max-width: 700px;
                background-color: $inputblue;
                padding: 10px;
                border: 0px;
                border-radius: 10px;
            }
            .ml_btn-wrap{
                display: inline-block;
                padding-right: 15px;
            }
        }

        .table-editor-to-assign{
            display: flex;
            flex-direction: column;
            gap: 15px;

            .center-loading{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            #editor-list-to-assign{
                height: 40vh;
                overflow: auto;
            }

            .table-row{
                display: flex;
                gap: 10px;
                align-items: center;

                .editor-name{ width: 80%; }

                .editor-action{
                    .assign-icon{
                        width: 40px;
                        height: 40px;
                        display: block;
                        cursor: pointer;
                        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/bien.svg');
                        &:hover{ transform: scale(1.1); }
                    }
                }
            }
        }

        .ml-modal-card-sm{
            background-color: white;
            min-height: 10%;
            max-height: 80%;
            border-radius: 3px;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 40%;
            min-width: 300px;
        }
    }

    .ml_left_wrap{
        width: 48%;
        height: 100%;
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        @include shadow;
        display: inline-block;
        vertical-align: top;

        .ml_l_header{
            width: 95%;
            padding: 20px;
            position: relative;
            border-radius: 10px;
            position: relative;

            img{
                width: 50px;
                position: absolute;
                top: 12px;
                right: 20px;
                cursor: pointer;
                border-radius: 7px;
            }

            img:hover{
                @include shadow;
            }
        }
        .bk_data_name{
            margin-top: 10px;
            &:hover{
                .ml-tooltipname-triangle{
                    display: block;
                }
            }
            .ml-tooltipname-triangle{
                position: absolute;
                top: 50px;
                left: 40px;
                background-color: white;
                @include shadow;
                border-radius: 5px;
                padding: 10px;
                z-index: 20;
                display: none;
            }
        }

        .bk_data_name .ml_headline-4 {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            line-height: normal;
            margin: 0px;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .ml_right_wrap{
        width: 48%;
        position: relative;
        background-color: #fff;
        @include shadow;
        border-radius: 20px;
        font-size: 14px;
        display: inline-block;
        margin-left: 20px;

        .ml_l_header{
            width: 95%;
            padding: 20px;
            position: relative;
            border-radius: 20px;
        }
        

        .ml_input-cb-wrap{
            display: inline-block;
            margin-right: 20px;
        }


        form{
            display: inline-block;
            width: fit-content;

            .ml_input-psw:focus{
                background-color: #f1fcfc!important;
            }

            .ml_input-psw-icon-wrap{
                width: 100%;
                max-width: 320px;
            }

            input{
                font-size: 12px;
                width: 100%;
                margin-left: 0px;
                box-sizing: border-box;
            }
    
            input::placeholder{
                font-size: 12px;
            }
        }

        

        .ml_search_result{
            display: inline-bock;
            cursor: pointer;
            color: $ldark;
            position: absolute;
            top: 0px;
            height: 36px;
            background-color:#f1fcfc;
            padding-left: 10px;
            vertical-align: top;
            padding-right: 20px;
            border-radius: 20px;
            right: 0;

            
            .ml_search_result_count{
                display: inline-block;
                height: 40px;
                vertical-align: middle;
            }

            .ml-search_result_icon_clean{
                display: inline-block;
                width: 15px;
                height: 34px;
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg);
                background-repeat: no-repeat;
                background-size: 250%;
                background-position: 50% 50%;
            }
            .ml-search_result_icon_clean:hover{
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');

            }
        }

        .ml-search_result_icons_wrap{
            border-left: 1px solid var(--primary);
            border-right: 1px solid var(--primary);
            padding: 0px 5px;
            margin: 0px 5px;
            display: inline-block;
            vertical-align: top;

            .ml-search_result_icons_wrap{
                display: inline-block;
            }

            .ml-search_result_icon_up{
                width: 15px;
                height: 15px;
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                transform: rotate(180deg);
                background-repeat: no-repeat;
                background-size: 200%;
                background-position: 50% 50%;
                display: block;
            }
            .ml-search_result_icon_down{
                width: 15px;
                height: 15px;
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
                background-repeat: no-repeat;
                background-size: 200%;
                background-position: 50% 50%;
                display: block;
            }

            .ml-search_result_icon_up:hover{
                width: 15px;
                height: 15px;
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
                transform: rotate(180deg);
                background-repeat: no-repeat;
                background-size: 200%;
                background-position: 50% 50%;
                display: block;
            }
            .ml-search_result_icon_down:hover{
                width: 15px;
                height: 15px;
                background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/flecha_desplegar.svg');
                background-repeat: no-repeat;
                background-size: 200%;
                background-position: 50% 50%;
                display: block;
            }

            

        }


        .ml_paragraph{
            display: inline-block;
        }

        .ml_btn-case-sensitive{
            color: var(--primary);
            padding: 10px;
            border-radius: 5px;
            background-color: white;
            @include shadow;
            cursor: pointer;

            &-on{
                color: $white;
                padding: 10px;
                border-radius: 5px;
                background-color:var(--primary);
                cursor: pointer;
            }
            strong{
                pointer-events: none;
            }
        }

        .i_active{
            background-color: var(--primary);
            color: $white;
        }

        .ml_btn-case-sensitive:hover{
            color: $white;
            padding: 10px;
            border-radius: 5px;
            background-color:var(--primary);
            cursor: pointer;
        }

        .ml_btn-case-sensitive-on:hover{
            color: var(--primary);
            padding: 10px;
            border-radius: 5px;
            background-color: white;
            @include shadow;
            cursor: pointer;
        }

        .i_active:hover{
            background-color: white;
            color: var(--primary);
            @include shadow;
        }
    }

    .p_found{ cursor: pointer; }
    
    .ml_left_animate_wrap{
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        margin-bottom: 10px;
        margin-left: 10px;
        display: inline-block;
        max-height: 100%;
        height: 100%;

        .ml_l_sub_header{
            width: 100%;
            padding-inline: 20px;
            box-sizing: border-box;
            height: 50px;
            position: relative;
            border-radius: 15px;
            @include shadow;

            .ml_alert_counter{
                padding-left: 5px;
                &::before{
                    content: ' - ';
                }
            }

            .ml_open_close_button {
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg);
                transform: rotate(90deg);
                width: 30px;
                height: 30px;
                cursor: pointer;
                @include shadow;
                border-radius: 5px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-color: $white;
                background-size: 150%;
            }

            .ml_bullet{
                width: 10px;
                height: 10px;
                border-radius: 50px;
                display: inline-block;
            }

            .ml_dropdown-click{
                position: relative;
                display: inline-block;
                vertical-align: top;

                .ml_dropbtn {
                    align-items: center;
                    border: none;
                    cursor: pointer;
                    border-radius: 3px;
                    cursor: pointer;
                    display: inline-block;
                    vertical-align: top;
                }

                .ml_dropbtn_img{
                    height: 30px;
                    width: 40px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_display.svg');
                    background-repeat: no-repeat;
                    display: inline-block;
                    background-size: cover;
                    pointer-events: none;
                    border-radius: 5px;
                    background-position: 50%;
                    
                }

                .ml_dropbtn_index_img{
                    height: 30px;
                    width: 40px;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/menu_index_display.svg');
                    background-repeat: no-repeat;
                    display: inline-block;
                    background-size: cover;
                    pointer-events: none;
                    border-radius: 5px;
                    background-position: 50%;
                }

                .ml_dropbtn_img:hover, .ml_dropbtn_index_img:hover{
                    @include shadow;
                }

                .ml_dropdown-content{
                    @include shadow;
                    padding: 10px;
                    text-align: center;
                    border-radius: 10px;
                    min-width: 182px;
                    position: absolute;
                    z-index: 1;
                    background-color: $white;
                    
                    p{
                        margin: 5px auto;
                        cursor: pointer;
                    }
                    p:hover{
                        color:var(--primary);
                    }

                    .ml_op_active{
                        font-weight: 700;
                    }

                    
                }
            }
        }

        .ml_show_ref{
            height: 30px;
            width: 30px;
            border: 1px solid var(--primary);
            border-radius: 10px;
            background-image: url("https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg");
            transform: rotate(90deg);
            background-repeat: no-repeat;
            display: inline-block;
            background-size: cover;
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 20px;
        }

        .ml_show_ref:hover{
            background-color: var(--primary);
            background-image: url("https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg");
        }

        .ml_update_law_button{
            background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/actualizar.svg);
            width: 40px;
            height: 30px;
            cursor: pointer;
            @include shadow;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: $white;
            display: inline-block;
        }

        .ml_big_card_content{
            width: 95%;
            padding: 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;
            margin-top: 10px;
            height: 77%;
            overflow-y: scroll;
            font-family: 'Spartan', sans-serif;
            .index_mode{
                .law_article,
                .law_preamble,
                .law_sign
                {
                    font-weight: 400;
                }
            }
        }
    }

    .ml_lockscroll,.ml_lockscroll_closed{
        height: 40px;
        width: 40px;
        @include shadow;
        border-radius: 5px;
        position: absolute;
        left: 48%;
        top: 30%;
        z-index: 99;
        background-size: 100%;
        background-position:50% 50%;
        cursor: pointer;
    }

    .ml_lockscroll{
        background-color: $white;
        background-image: url("https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/open.svg");
    }

    .ml_lockscroll_closed{
        background-color: var(--primary);
        background-image: url("https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/closed.svg");
    }

    .ml_right_animate_wrap{
        width: 48%;
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        margin-bottom: 10px;
        margin-left: 10px;
        display: inline-block;
        vertical-align: top;
        max-height: 100%;
        height: 100%;


        .ml_preloader{
            position: absolute;
            left: 50%;
            top: 44%;
            z-index: 10;

            img{
                width: 60px;
            }
           
        }

        .ml_r_sub_header{
            @apply flex items-center;
            @include shadow;
            box-sizing: border-box;
            width: 100%;
            padding-inline: 20px;
            position: relative;
            border-radius: 15px;
            margin-left: 10px;
            height: 50px;

            .ml_open_boe_button{
                background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/mundo.svg);
                width: 40px;
                height: 30px;
                cursor: pointer;
                @include shadow;
                border-radius: 5px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-color: #ffffff;
                display: inline-block;
            }
        }

        .ml_select_ref_wrap{
            display: inline-block;
            width: fit-content;
            margin-left: 10px;

            .ml_select{
                font-size: .8em;
            }
            .ml_options{
                overflow-y: scroll;
                max-height: 200px;
            }
            .ml_select_option{
                font-size: .8em!important;
            }
        }

        .ml_open_close_button-right {
            background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/ver.svg);
            width: 40px;
            height: 30px;
            cursor: pointer;
            @include shadow;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: $white;
        }

        

        .ml_big_card_content{
            width: 95%;
            padding: 20px;
            position: relative;
            @include shadow;
            border-radius: 20px;
            margin-top: 10px;
            height: 77%;
            overflow-y: auto;
            margin-left: 10px;
            .parrafo, .parrafo_2{
                margin-top: 5px;
                margin-bottom: 5px;
                padding: 5px 0px;
            }
        }
    }

    .ml_bottom_btn_wrap {
        width: 100%;
        height: 100%;
        @include shadow;
        -webkit-appearance: none;
        background-color: #fff;
        padding: 10px 0px 20px 0px;
        text-align: center;

        div{
            display: inline-block;
        }

        .ml_btn-alert-wrap{
            text-align: center;
            width: 50%;
            display: block;
        }
        .btn_forAdmin{
            position: absolute;
            top: 10px;
            right: 20px;
        }
    }

    .ml_animation_show_full{ animation: show_fully .7s; }

    .ml_animation_partial{ animation: reset_width .7s; }
}

.ml_width-50{ width: 48%; }

.ml_width-100{ width: 98%; }

// EXTRAS
@keyframes show_fully {
    to {
        width: 100%;
    }
}

@keyframes reset_width {
    to {
        width: 50%;
    }
}

.ml_kb_main_wrap{
    .ml_highlightWord {
        font-weight: bold;
        background-color: var(--warning);
        border-radius: $radius;
        pointer-events: none;
    }
    
    .ml_highlightParagraph {
        border-radius: $radius;
        background-color: $inputblue;
    }
    
    .ml_highlightParagraphSelected {
        padding: 10px 0px;
        border-radius: $radius;
        background-color: $inputblue;
    }
    
    .ml_word_not_found {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        color: gray;
    }

    %alert_box {
        border-radius: $radius;
        padding: 10px 0px;
        box-sizing: border-box;
    }
    
    .node {
        cursor: pointer;
        table{
            pointer-events: none;
        }
    }
    
    .node_selected {
        @extend %alert_box;
        border: 2px solid var(--primary);
        padding-left: 5px;
    }

    .focus{
        animation: focus-node 3s;
    }
    @keyframes focus-node{
        0%{ background-color: white}
        50%{ background-color: var(--primary4); }
        100%{ background-color: white}
    }

    div[data-is_repealed="true"]{
        color: var(--accent);
    }
    
    .ml_alert_paragraph {
        @extend %alert_box;
        background-color: var(--lprimary);
    }

    table{ border-collapse: collapse; }
    tr{ border-collapse: collapse; }
    td,th{
        border: 1px solid $ldark;
        border-collapse: collapse;
        padding: 10px;
        margin: 0px;
    }

    @import './assets/preloader.scss';
    @import './assets/toolbox.scss';
}

@media (max-height: 950px) {
    .ml_kb_main_wrap{
        .ml_left_wrap .ml_l_header{
            padding: 15px 20px;
        }
        .ml_right_wrap .ml_l_header{
            padding: 15px 5px 5px 20px;
        }
        .ml_kb_main_middle_wrap{
            margin-bottom: 0px;
        }
        .ml_left_animate_wrap .ml_l_sub_header .ml_open_close_button{
            top: 5px;
        }
    }
}

@media (max-height: 950px) {
    .ml_kb_main_wrap .ml_bottom_btn_wrap{
        padding: 2px auto!important;
    }

    .ml_kb_main_up_wrap{
        height: 20vh;
    }
    .ml_kb_main_middle_wrap{
        height: 60vh;
    }
    .ml_kb_main_down_wrap{
        height: 20vh;
    }
}
