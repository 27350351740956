.page-update-law-version{
  padding: 0px 40px 0px 40px;

  .tabs{
    @include shadow;
    background: white;
    padding: 5px 15px;
    top: -50%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    transition: top .3s ease-in-out;
    &.left{ left: 25%; }
    &.right { right: 25%; }
    &.middle{
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover{ 
      p { font-weight: bolder; }
    }
    &.show{ top: 0px; }
  }

  .title-container{
    @include shadow;
    background-color: white;
    height: 8vh;
    display: flex;
    align-items: center;
    border-radius: 20px;
    transition: all .3s ease-in-out;
    &.fold{
      overflow: hidden;
      height: 0px;
    }
    .ml_headline-4{
      padding: 0px 40px;
      font-size: 20px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: normal;
      margin: 0px;
      width: 80%;
    }
    &::after{
      content: attr(data-title);
      @include shadow;
      padding: 10px 30px;
      max-width: 80%;
      position: absolute;
      top: -20px;
      left: 40px;
      opacity: 0;
      background-color: white;
      border-radius: 10px;
      transition: all .3s ease-in-out;
    }
    &:hover{
      &::after{ opacity: 1; }
    }
  }

  .panels{
    .info-head{
      height: 8vh;
      @include shadow;
      border-radius: 20px;
      transition: all .3s ease-in-out;
      &.overflowHidden{ overflow: hidden; }
      .kb-state{
        transition: all .3s ease-in-out;
        .ml_bullet{
          width: 10px;
          height: 10px;
          border-radius: 50px;
          background-color: var(--warning);
          display: inline-block;
          margin-right: 5px;
        }
        &.no-changes{
          filter: grayscale(0.5);
          opacity: .4;
        }
      }
      .open-link{
        background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/mundo.svg);
        width: 40px;
        height: 40px;
        cursor: pointer;
        @include shadow;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #ffffff;
        transition: all .3s ease-in-out;
        &:hover{ transform: scale(1.1); }
      }
      &.fold{ height: 0px; }
      .arrow-container{
        @include shadow;
        cursor: pointer;
        padding: 1px 10px 1px 15px;
        border-radius: 9px;
        background-color: white;
        width: 120px;
        justify-content: center;
        p{ pointer-events: none; }
        .ml-arrow{
          background-image: url("https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg");
          width: 30px;
          height: 30px;
          border-radius: 5px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 150%;
          transition: all .5s ease-in-out;
          pointer-events: none;
        }
        &:hover{
          p { font-weight: 500; }
        }
        &.active{
          p { font-weight: 500; }
          .ml-arrow{ transform: rotate(180deg); }
        }
        &.disabled{
          opacity: .3;
          pointer-events: none;
        }
      }
      .nav-menu{
        background-color: white;
        @include shadow;
        border-radius: 10px;
        top: 65px;
        left: 42%;
        max-height: 0px;
        overflow-y: scroll;
        z-index: 10;
        transition: max-height .3s ease-in;
        &.open{ max-height: 300px; }
        p{
          padding: 15px 30px;
          margin: 1px 15px;
          box-sizing: border-box;
          cursor: pointer;
          border-radius: 10px;
          overflow: hidden;
          width: 300px;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex-shrink: 0;
          &.fst-m{ margin: 15px 15px 1px 15px; }
          &.lst-m{ margin: 1px 15px 15px 15px; }
          &:hover{ font-weight: bold; }
        }
        &.derogate{
          p{ background-color: var(--accent4); }
        }
        &.newNode{
          p{ background-color: var(--success2); }
        }
      }
    }
    .panel-body{
      @include shadow;
      border-radius: 20px;
      .kb-loading{
        background: white;
        @include shadow;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin: auto;
        height: 70px;
        padding: 0px 20px;
        border-radius: 20px;
        display: none;
        .progress-container{
          height: 30px;
          width: 0px;
          background-color: var(--primary5);
          border-radius: 20px;
          .progress-bar{
            width: 0%;
            height: 30px;
            border-radius: 20px;
          }
        }
      }
      &.loadLaw {
        .kb-loading{
          display: flex;
          width: 200px;
          .progress-container{ 
            width: 180px; 
            background-color: white;
          }
        }
      }
      &.analyzing {
        .kb-loading{
          display: flex;
          width: 500px;
          .progress-container{
            height: 30px;
            width: 450px;
            background-color: var(--primary5);
            border-radius: 20px;
            .progress-bar{
              height: 30px;
              border-radius: 20px;
              background-color: var(--primary2);
              transition: background-color .3s ease-in;
            }
          }
        }
      }
    }
    .law-container{
      padding: 30px 40px 0px 40px;
      height: 97%;
      overflow: auto;
      box-sizing: border-box;
      .to-derogate{ 
        background-color: var(--accent4); 
        cursor: pointer;
        * { pointer-events: none; }
      }
      .to-add{ 
        background-color: var(--success2); 
        cursor: grab;
        * { pointer-events: none; }
      }
      .focus{ animation: focus-node 3s; }
      .ml-repealed, .node-disabled{
        opacity: .3;
        pointer-events: none;
      }
      .node-modified{ background-color: var(--warning4); }
      @keyframes focus-node{
        0%{ background-color: white}
        50%{ background-color: var(--primary4); }
        100%{ background-color: white}
      }
    }
  }

  .title-container, .panels{
    .minimized{
      height: 16px;
      width: 16px;
      top: 15px;
      right: 30px;
      cursor: pointer;
      &::after{
        content: "";
        height: 3px;
        width: 16px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0px;
        background-color: var(--primary);
      }
      &:hover{ transform: scale(1.1); }
    }
  }

  .buttons-section{
    @include shadow;
    border-radius: 20px;
    height: 10vh;
    margin: 12px 0px 0px 0px;
  }

  .ml-modal-bg{
    background-color: #01393a96;
    position: absolute;
    height: 100%;
    z-index: 110;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .ml_close{
      background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position:50% 50%;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      &:hover{
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
      }
    }

    .ml-modal-card{
      background-color: white;
      max-height: 80%;
      border-radius: 30px;
      padding: 30px;
      width: auto;
      min-width: 300px;
      &.sm { width: 35%; }
      &.auto { width: auto; }
      .ml-modal-content-wrap{ 
        padding: 40px 50px 10px 50px; 
        .section-one, .section-two{
          display: none;
        }
        &.show-section-one{
          .section-one{ display: flex; }
        }
        &.show-section-two{
          .section-two{ display: flex; }
        }
        .example-container{
          .block-grey, .block-new{
            box-sizing: border-box;
            padding: 10px 15px;
            border-radius: 15px;
            @include shadow;
          }
          .block-grey{ background-color: #cecece; }
          .block-new{ background-color: var(--primary2); }
        }
        .prg-affected-container{
          max-height: 300px;
          overflow: auto;
          .prg-item{
            cursor: pointer;
            p, div{ pointer-events: none; }
            &:hover{
              p { font-weight: bold; }
              div{ transform: scale(1.1) rotate(-90deg); }
            }
            .ml_navigate_to_prg{
              width: 40px;
              height: 40px;
              display: block;
              transform: rotate(-90deg);
              background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg);
            }
          }
        }
      }
    }
  }
}

