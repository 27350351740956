%loader {
  background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/statics/animated-icons/loading.gif');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position:50% 50%;
}

.mq-loader-bg-gray {
}

.mq-loader-bg-black {
}

.preloader {
  @extend %loader;
  position: absolute;
  left: 50%;
  top: 44%;
  z-index: 10;
}

.onload {
  @extend %loader;
  display: flex;
  margin: auto;
}


