:root{
	--title-1: 36px;
	--title-2: 28px;
	--title-3: 24px;
	--title-4: 20px;
	--title-5: 18px;
	--title-6: 16px;
	--paragraph-14: 14px;
	--paragraph-12: 12px;
	--paragraph-11: 11px;
	--paragraph-10: 10px;
}
