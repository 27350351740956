@use "sass:math";
@import url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/css/fontello-codes.css');

@font-face {
    font-family: 'fontello';
    src: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.eot?25412964');
    src: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.eot?25412964');
    src: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.eot?25412964#iefix') format('embedded-opentype'),
    url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.woff?25412964') format('woff'),
    url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.ttf?25412964') format('truetype'),
    url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.svg?25412964#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin getMargins($size) {
   margin: math.div($size, 4);
  //  font-size:$size;
   width: $size;
   height: $size;
}

@layer components {
%icon{
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-decoration: inherit;
   text-align: center;
   text-transform: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "fontello";
}

.icon-1{
   @extend %icon;
   &.icon-margins{
      @include getMargins(1.5em);
   }
}

.icon-2{
   @extend %icon;
   &.icon-margins{
      @include getMargins(1.25em);
   }
}
.icon-3{
   @extend %icon;
   &.icon-margins{
      @include getMargins(1em);
   }
}

.icon-1-clean{
   @extend %icon;
}

.size_48{ font-size: 3rem; }

.size_36{ font-size: 2.25rem; }

.size_24 { font-size: 1.5rem; }

.size_20 { font-size: 1.25rem; }

.size_18 { font-size: 1.125rem; }

.size_16{ font-size: 1rem; }

.size_14{ font-size: 0.875rem; }

.size_12{ font-size: 0.75rem; }

.size_10{ font-size: 0.625rem; }

.size_8{ font-size: 0.5rem; }

}
