.ml_create_law{

	.ml_headline-3{
		padding: 40px 0px 0px 40px;
	}
	.lef_side{
		width: 49%;
		box-sizing: border-box;
		display: inline-block;
	}
	.right_side{
		width: 50%;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: top;
		.ml_paragraph-h{
			padding-left: 40px;
		}
		.head_line{
			height: 1px;
			width: 100%;
			background-color: var(--primary);
		}
		.ml_header_list{
			box-sizing: border-box;
			padding: 5px 15px;
			span{
				display: inline-block;
				&.ml_head_id{
					width: 10%;
				}
				&.ml_head_name{
					width: 60%;
				}
				&.ml_head_permission{
					width: 40%;
				}
			}
		}
		.ml_list_container{
			box-sizing: border-box;
			padding: 5px 15px;
			position: relative;
			height: 60vh;
			overflow-y: auto;
			.ml_row{
				margin: 15px 0px;
				height: 40px;
				position: relative;
				border-bottom: 1px solid var(--primary)!important;
				span{
					display: inline-block;
					&.ml_paragraph{
						// padding-top: 10px;
					}
					&.ml_row_id{
						width: 10%;
					}
					&.ml_row_name{
						width: 65%;
					}
					&.ml_row_permission{
						width: 40%;
						.ml_input_wrap{
							display: inline-block;
							margin: 0px;
							width: 35%;
							input, label{
								cursor: pointer;
							}
						}
					}
					&.ml_remove_icon{
						background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/tacho_basura.svg);
						width: 40px;
						height: 40px;
						position: absolute;
						top: 0px;
						cursor: pointer;
					}
				}
			}
		}
	}

	.ml-modal-bg{
		background-color: #01393a96;
		position: absolute;
		height: 100vh;
		z-index: 110;
		width: 100vw;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.ml-modal-card{
			background-color: white;
			max-height: 80%;
			border-radius: 30px;
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 30px;
			width: auto;
			min-width: 750px;
			.ml_close{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
				width: 40px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: 200%;
				background-position:50% 50%;
				cursor: pointer;
				position: absolute;
				top: 20px;
				right: 20px;
				&:hover{
					background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
				}
			}
			.ml-modal-content-wrap{
				padding: 30px;
				.ml_paragraph-h{
					padding-left: 20px;
					margin-bottom: 5px;
				}
				.ml_paragraph{
					padding-top: 0px;
				}
				.head_line{
					height: 1px;
					width: 100%;
					background-color: var(--primary);
				}
				.ml_header_list{
					box-sizing: border-box;
					padding: 5px 15px;
					span{
						display: inline-block;
						&.ml_head_id{
							width: 7%;
						}
						&.ml_head_name{
							width: 65%; // It must be 45 if permission is enabled
						}
						&.ml_head_permission{
							width: 35%;
						}
					}
				}
				.ml_list_container{
					box-sizing: border-box;
					padding: 5px 15px;
					position: relative;
					height: 60vh;
					overflow-y: auto;
					.ml_row{
						height: 40px;
						position: relative;
						span{
							display: inline-block;
							&.ml_paragraph{
								padding-top: 10px;
							}
							&.ml_row_id{
								width: 7%;
							}
							&.ml_row_name{
								width: 65%; // It must be 45 if permission is enabled
							}
							&.ml_row_permission{
								width: 35%;
								.ml_input_wrap{
									display: inline-block;
									margin: 0px;
									width: 45%;
									input, label{
										cursor: pointer;
									}
								}
							}
							&.ml_add_icon{
								background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/add.svg);
								width: 40px;
								height: 40px;
								position: absolute;
								top: 0px;
								cursor: pointer;
								&:hover{
									background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/mas.svg');
								}
							}
						}
					}
				}
				.ml_btn-wrap{
					padding-top: 20px;
					padding-left: 0px;
					margin-left: 0px;
					padding-bottom: 20px;
				}
			}
		}
	}

	.ml_meta_wrap{
		padding:40px;
	}
	.ml_input_wrap{
		margin-top: 10px;
	}

	.ml_btn-wrap{
		margin-left:0px !important;
	}

	.ml_input_law_name{
		max-width: 400px;
		width: 90%;
	}
}

