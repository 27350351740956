@use 'sass:string';
@use 'sass:list';
@use 'sass:math';

// COLORS
$black: #0d0d0d;
$text_color: #707070;
$dark_gray: #b4b4b4;
$light_gray: #efefef;
$light_background: white;
$alert_paragraph: #ffff9d;
$error_color: var(--accent);
$error_color_light: #ffd8d8;

.mq-black {color: $black};
.mq-dark-gray {color: $dark_gray};
.mq-error { color: $error_color !important};
.mq-text { color: $text_color };

// BORDER
$radius: 3px;
$border: 1px solid $black;
.mq-width-block { width: max-content }
.mq-width-fc { width: fit-content; }
.mq-b-radius { border-radius: $radius }

// PADDINGS
$p_tn: .3em;
$p_xs: .6em;
$p_sm: .9em;
$p_md: 1.2em;
$p_lg: 1.5em;
$p_xl: 1.8em;
$p-sizes: tn, xs, sm, md, lg, xl;
@for $i from 1 through 6 {
  .mq-p-#{nth($p-sizes, $i)} { padding: .3em * $i }
  .mq-pt-#{nth($p-sizes, $i)} { padding-top: .3em * $i }
  .mq-pr-#{nth($p-sizes, $i)} { padding-right: .3em * $i }
  .mq-pb-#{nth($p-sizes, $i)} { padding-bottom: .3em * $i }
  .mq-pl-#{nth($p-sizes, $i)} { padding-left: .3em * $i }
}

.p-relative {
  position: relative;
}

// MARGINS
.mq-ml-auto { margin-left: auto }
.mq-mr-auto { margin-right: auto }
$m-xs: .3em; $m-sm: .6em; $m-md: .9em; $m-lg: 1.2em; $m-xl: 1.5em;
$margin: "margin", "margin-top", "margin-right", "margin-bottom", "margin-left";
$m-sizes: xs, sm, md, lg, xl;
@for $i from 1 through 5 {
  .mq-#{nth([m, mt, mr, mb, ml], $i)}-auto { #{unquote(nth($margin, $i))}: auto }
}
@for $i from 1 through 5 {
  .mq-m-#{nth($m-sizes, $i)} { margin: .3em * $i }
  .mq-mt-#{nth($m-sizes, $i)} { margin-top: .3em * $i }
  .mq-mr-#{nth($m-sizes, $i)} { margin-right: .3em * $i }
  .mq-mb-#{nth($m-sizes, $i)} { margin-bottom: .3em * $i }
  .mq-ml-#{nth($m-sizes, $i)} { margin-left: .3em * $i }
}
@for $i from 1 through 5 {
  .vh-mt-#{nth($m-sizes, $i)} { margin-top: .25vh * $i }
  .vh-mb-#{nth($m-sizes, $i)} { margin-bottom: .25vh * $i }
}

// WIDTH SIZING
$i: 0;
@while $i <= 100 {
  $value: 0%;
  @if $i != 0 {
    $value: percentage(math.div($i, 100));
  }
  .width-#{$i} { width: $value }
  $i: $i + 5;
}
.mq-fc { width: fit-content };

// HEIGHT SIZING
$i: 0;
@while $i <= 100 {
  $value: 0%;
  @if $i != 0 {
    $value: percentage(math.div($i, 100));
  }
  .height-#{$i} { height: $value }
  $i: $i + 5;
}

.mq-hvh-73 { height: 73vh !important; }

// OTHERS
.mq-d-flex { display: flex }
.mq-flex-column { flex-direction: column }
.mq-jc-center { justify-content: center }
.mq-jc-around { justify-content: space-around }
.mq-jc-end { justify-content: flex-end; }
.mq-ai-center { align-items: center }
.mq-as-normal { align-self: normal; }

// TEXT SIZING
$font-xs: .8rem;
$font_sm: 1.2rem;
$font_md: 1.6rem;
$font_lg: 2rem;
$font-xl: 2.4rem;
@for $i from 1 through 5 {
  .font-#{nth($m-sizes, $i)} { font-size: .4rem + 0.4 * $i }
}
.text-bold { font-weight: bold }
.text-link { 
  text-decoration: underline;
  cursor: pointer;
}
.mq-text-center { text-align: center !important; }

.mq-box {
  margin: $p_xs;
  box-sizing: border-box;
}

%card {
  background-color: $light_gray;
  padding: $p_xs $p_md;
  box-sizing: border-box;
  display: flex;
  border-radius: $radius;
}

%shadow {
 -webkit-box-shadow: -4px 4px 6px 1px $dark_gray;
  box-shadow: -4px 4px 6px 1px $dark_gray;
}

.mq-shadow { @extend %shadow }
.mq-bs { box-sizing: border-box; }

.ml_field{
  &.ml_inp-error{
    .ml_tx_error{
      display: block;
      padding-top: 5px;
    }
  }
  span{
    display: none;
  }
}
