.ml_cms_create_PrPfs{
    padding:40px;

    .ml_input-rb-label{
        display: inline-block;
        padding-right: 20px;
    }

    .create-pr-pf-wrap{
        margin: 30px auto;
        .ml_input_wrap, .element-select{
            width: 15vw;
        }
    }
    form{
        margin:20px auto; 
    }
    label{
        margin-top: 20px;
    }
    .element-select {
        display: inline-block;
    }
}

.ml_button_wrap{
    margin-top: 25px;
}
