.btn {
  padding: 10px 2.5rem;
  border-radius: $radius * 10;
  border-width: 1px;
  background-color: $text_color;
  color: $light_background;
  &:hover {
    background-color: #7d7d7d;
  }
}

.btn-secondary {
  opacity: 0.5;
}

.btn-disable {
  opacity: 0.5;
  cursor: not-allowed;
}
