@forward "./styles/utilities";
@import 'bed-path-dot-home/styles.scss';
@import 'components/styles.scss';
@import 'screens/styles.scss';

:host, body {
  font-family: 'Spartan', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  h1 {
    margin-top: 0;
  }

  i {
    color: $black;
  }

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary);
  }
}

* {
  font-smooth: always;
  scrollbar-width: thin;
  scrollbar-color: var(--primary) white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: none;
}

.main_body {
  display: flex;
  flex-wrap: wrap;
}

.hidden {
  display: none!important;
}

.ml_cms_wrap{
  display: flex;
  flex: 1 1 0;
  height: 100vh;
  overflow: hidden;
}

.ml_menu_wrap{
  width: 20%;
  background-color:red;
  height: 100vh;
  overflow:hidden;
  display: inline-block;
  vertical-align: top;
}

.ml_cms_screen_wrap{
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.ml_no_menu{
  .ml_cms_screen_wrap{
    width: 100%;
  }
}

.ml_menu_compressed{
  .ml_cms_screen_wrap{
    width: 90%;
  }
}

.big-title-content{
  position: relative;
  &:after{
    content: attr(data-value);
    width: 90%;
    background-color: white;
    position: absolute;
    top: 30px;
    left: 0px;
    opacity: 0;
    padding: 15px 25px;
    border-radius: 20px;
    z-index: 1;
    transition: opacity .5s ease-in-out;
    @include shadow;
  }
  &:hover:after{
    opacity: 1;
  }
}

.hightlight-success{
  @apply relative z-10;
  &:before{
    @apply absolute w-full h-2 bg-success;
    content: " ";
    border-radius: 8px;
    bottom: -2px;
    z-index: -1;
  }
}
