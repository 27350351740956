.modal-objs-to-publish{
    .table-objs{
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: 40vh;
        overflow: auto;

        .center-loading{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .table-row{
            display: flex;
            gap: 25px;
            align-items: center;

            .obj-id{ width: 20px; }
            .obj-name{ 
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                min-width: 150px;
            }
            .obj-editor{ width: 150px; }
            .obj-type{ width: 60px; }

            .obj-action{
                width: 60px;
                .assign-icon{
                    width: 40px;
                    height: 40px;
                    display: block;
                    cursor: pointer;
                    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/lapiz.svg');
                    &:hover{ transform: scale(1.1); }
                }
            }
        }
    }
}

.ml_cms_pr_pf_list{
  width: 100%;
  position: relative;
  display: inline-block;
  height: auto;
  vertical-align: top;
  overflow-y: scroll;


  .ml_fixed{
    /*position: fixed;*/
    background-color: $white;
    padding-left: 5em;
    z-index: 100;
    width: 100%;
  }

  .ml_pr_pf_list_wrap{
    /*position: fixed;*/
    /*padding-left: 5em;*/
    width: 90%;
    min-width: 1200px;

    .ml_headline-3{
      margin-top: 2em;
    }
    .ml_pr_pf_list_filters_wrap{
      position: relative;
      width: 100%;

      .ml_btn-wrap{
        position: absolute;
        right: 0;
        margin:0px;
      }

      .ml_input-psw{
        width: 250px;
      }
      .ml_i_search{
        width: 30px;
        height: 30px;
        margin-top: 2px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/lupa.svg');
      }
      .ml_i_clear{
        width: 30px;
        height: 30px;
        margin-top: 2px;
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/mal.svg');
      }

      .wrap_filters{
        margin-top: 20px;
      }

      form{
        .ml_btn-wrap{
          position: relative;
          margin-left: 55px;
          cursor: pointer;
          display: inline-block;
        }

        .ml_btn-icon-h::before{
          content: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/actualizar.svg);
          position: absolute;
          width: 40px;
          height: 40px;
          left: -40px;
          top: -10px;
        }
      }
    }
  }


  .ml_search_result_wrap{
    width: 100%;
    margin-top: 20px;
    padding-left: 5em;
    z-index: 0;
    /*padding-top: 223px;*/


    .ml_reference_labels{
      display: flex;
      width: 100%;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      padding-left: 13px;

      &_name{
        flex: 1;
        padding-left: 16px;
      }

      &_create, &_update{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &_create{ width: 100px; }

      &_update{ width: 160px; }

      &_editor{ width: 150px; }

      &_links{ width: 100px; }

      &_type{ width: 90px; }
      
      &_actions { width: 110px; }

      .ml_chevron-down, .ml_chevron-up{
        width: 20px;
        height: 20px;
        display: inline-block;
        cursor: pointer;

      }

      .ml_chevron-down{
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
        transform: scale(2);
      }

      .ml_chevron-up{
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
        transform: rotate(180deg) scale(2);
      }

      .ml_reference_labels_update,.ml_reference_labels_create{
        span, p{
          pointer-events: none;
        }
      }
    }

    .ml_textSearchResult{
      margin-bottom: 20px;
      color: #769b9b;
    }
  }

  /*Estilos rows*/

  .ml_loading_list_wrap{
    width: 100%;
    padding-bottom: 100px;
    margin-top: 50px;
    position: relative;

    .ml_loading_icon{
      width:50px;
      margin: 30px auto;
      text-align: center;
    }
  
    .pr_pf_row{
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 25px 0px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      margin-top: 0.9em;
      position: relative;
      @include shadow;
      * { box-sizing: border-box; }

      .pr_pf_data_name{
        flex: 1;
        padding-left: 16px;
        position: relative;
        .ml_legal{
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 300px;
        }
        &::before{
          content: attr(data-nm);
          position: absolute;
          bottom: 30px;
          background-color: white;
          padding: 5px 15px;
          border-radius: 10px;
          @include shadow;
          display: none;
          font-family: "Spartan", sans-serif;
          font-size: 0.8em;
          font-weight: 400;
        }
        &:hover{
          &::before{ display: unset; }
        }
      }

      .pr_pf_data_creation{ width: 100px; }

      .pr_pf_data_update{ width: 160px; }

      .pr_pf_data_user{ width: 150px; }

      .pr_pf_data_links{ width: 100px; }

      .pr_pf_data_type{ width: 90px; }

      .pr_pf_data_actions {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 110px;

        .ml_edit_icon, .ml_remove_icon, .ml_see_icon, .ml_revert_icon{
          width: 40px;
          height: 40px;
          display: block;
          cursor: pointer;
          &:hover{ transform: scale(1.1); }
        }

        .ml_edit_icon{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/lapiz.svg');
        }

        .ml_remove_icon{
          background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/borrar.svg');
        }

        .ml_see_icon{
          background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/ver.svg);
        }

        .ml_revert_icon{
          transform: rotate(90deg);
          background-image: url(https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg);
          &:hover{ transform: scale(1.1) rotate(90deg); }
        }
      }

      .ml-alert-triangle{
        background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/p/alerta.svg');
        width: 40px;
        height: 40px;
        position: absolute;
        top: 10px;
        margin-left: 20px;
      }

      .dot{
        height: 20px;
        width: 20px;
        background-color: var(--primary);
        border-radius: 50%;
        display: inline-block;

        &_draft{
          background-color: var(--warning);
        }

        &_published{
          background-color: var(--success);
        }
      }

      .label-state-container{
        position: absolute;
        display: flex;
        gap: 8px;
        bottom: 0px;
        left: 14px;
        .js_update_obj{
          cursor: pointer;
          &:hover{ font-weight: bold; }
        }

        .label-state{
          background-color: #EAEAEA;
          border-radius: 5px 5px 0px 0px;
          padding: 3px 10px;
          > span{
            height: 10px;
            width: 10px;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }
    }

    .pr_pf_draft{
      border-left: 13px solid var(--accent); 
    }
    .pr_pf_published{
      border-left: 13px solid var(--success); 
    }
    .pr_pf_revision{
      border-left: 13px solid var(--warning); 
    }
  }

  /*End estilos rows*/

  .txt_no_kb_wrap{
    margin: auto;
    text-align: center;
    margin-top: 50px;

    p{
      padding-bottom: 20px;
    }
  }

  .noResultTxt{
    padding: 30px;
    text-align: center;
    margin: auto;
    width: 100%;
    margin-top: 40px;

    img{
      width: 250px;
    }
  }
  .element-select {
    display: inline-block;
  }
}


@media (max-width: 1500px) {
  .ml_cms_pr_pf_list{
    .ml_pr_pf_list_wrap{
      padding-right: 400px;
    }

    .ml_create_new_prpf_btn{
      position: initial!important;
      margin-bottom: 20px!important;
      
    }

  }
  
}
