@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-ExtraLight.eot') format('embedded-opentype'),
		url('assets/Spartan-ExtraLight.svg') format('svg'),
		url('assets/Spartan-ExtraLight.woff') format('woff'),
		url('assets/Spartan-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-Light.eot') format('embedded-opentype'),
		url('assets/Spartan-Light.svg') format('svg'),
		url('assets/Spartan-Light.woff') format('woff'),
		url('assets/Spartan-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-Regular.eot') format('embedded-opentype'),
		url('assets/Spartan-Regular.svg') format('svg'),
		url('assets/Spartan-Regular.woff') format('woff'),
		url('assets/Spartan-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-Thin.eot') format('embedded-opentype'),
		url('assets/Spartan-Thin.svg') format('svg'),
		url('assets/Spartan-Thin.woff') format('woff'),
		url('assets/Spartan-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-Medium.eot') format('embedded-opentype'),
		url('assets/Spartan-Medium.svg') format('svg'),
		url('assets/Spartan-Medium.woff') format('woff'),
		url('assets/Spartan-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-SemiBold.eot') format('embedded-opentype'),
		url('assets/Spartan-SemiBold.svg') format('svg'),
		url('assets/Spartan-SemiBold.woff') format('woff'),
		url('assets/Spartan-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-Bold.eot') format('embedded-opentype'),
		url('assets/Spartan-Bold.svg') format('svg'),
		url('assets/Spartan-Bold.woff') format('woff'),
		url('assets/Spartan-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-ExtraBold.eot') format('embedded-opentype'),
		url('assets/Spartan-ExtraBold.svg') format('svg'),
		url('assets/Spartan-ExtraBold.woff') format('woff'),
		url('assets/Spartan-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: 100;
}

@font-face {
	font-family: 'spartan';
	src: url('assets/Spartan-Black.eot') format('embedded-opentype'),
		url('assets/Spartan-Black.svg') format('svg'),
		url('assets/Spartan-Black.woff') format('woff'),
		url('assets/Spartan-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: 100;
}
