%modal_card {
  background-color: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.ml-modal{
  background-color: #01393a96;
  position: absolute;
  height: 100vh;
  z-index: 111;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .ml_close{
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/cerrar.svg');
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 200%;
    background-position:50% 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .ml-modal-content-wrap{
    padding: 10px 60px;

    .ml_btns-wrap{
      text-align: center;
      margin: auto;
      div{
        display: inline-block;
        padding-right: 10px;
      }

      .ml_btn-wrap{
        display: inline-block;
        padding: 10px;
      }
    }
  }

  .ml_close:hover{
    background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/d/cerrar.svg');
  }

  .ml-modal-card-sm{
    @extend %modal_card;
    min-height: 10vh;
    max-height: 80vh;
    min-width: 30%;
    max-width: 70%;
  }

  .ml-modal-card-md{
    @extend %modal_card;
    min-height: 30%;
    max-height: 90%;
    min-width: 40%;
    max-width: 70%;
  }

  .ml-modal-card-lg{
    @extend %modal_card;
    min-height: 40%;
    max-height: 80%;
    min-width: 60%;
    max-width: 90%;
  }

  .ml-modal-card-xl{
    @extend %modal_card;
    min-height: 60%;
    max-height: 80%;
    min-width: 80%;
    max-width: 90%;
  }
}

.ml_toast{
  visibility: hidden;
  min-width: 200px;
  max-width: 450px;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: 30px;
  top: 30px;
  color: $phgrey;
  @include shadow;
  transform: translateY(-20vh);
  transition: all .5s ease-out;
  &.success{
    background-color: var(--success4);
  }
  &.warning{
    background-color: var(--warning4);
  }
  &.error{
    background-color: var(--accent4);
  }
  &.info{
    background-color: var(--primary4);
  }
  &.show_toast {
    visibility: visible;
    transform: translateY(0);
  }
}

