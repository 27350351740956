.toolbox {
	@include shadow;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 5px;
	position: fixed;
	background: white;
	display: flex;

	&.toolbox_show { opacity: 1; }

	&.toolbox_hide {
		opacity: 0;
		pointer-events: none;
	}

	.i_container{
		background-color: var(--primary);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		border-radius: 3px;
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin: 5px;

		i {
			color: $dark_gray;
			pointer-events: none;
		}
		span{
			display: block;
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-size: 200%;
			background-position: 50%;
			pointer-events: none;

		}
	}

	.ml_i_container{
		&.ml-icon-btn-up{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
				transform: rotate(180deg);
			}
		}
	   
		&.ml-icon-btn-down{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');

			}
		}
	  
		&.ml-icon-btn-left{
			height: 55px;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
				transform: rotate(90deg);

			}
		}

		&.ml-icon-btn-right{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_desplegar.svg');
				transform: rotate(270deg);

			}
		}

		&.ml-icon-btn-forward{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/doble-flecha.svg');
				transform: rotate(270deg);

			}
		}

		&.ml-icon-btn-to-top{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_con-tope.svg');
				transform: rotate(180deg);

			}
		}
	   
		&.ml-icon-btn-to-bottom{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/w/flecha_con-tope.svg');

			}
		}

		&.ml-icon-btn-up:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
			}
		}

		&.ml-icon-btn-down:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
			}
		}
		
		&.ml-icon-btn-left:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
			}
			
		}
	  
		&.ml-icon-btn-right:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
			}
			
		}

		&.ml-icon-btn-forward:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg');
			}
		}

		&.ml-icon-btn-to-top:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
			}
		}
	   
		&.ml-icon-btn-to-bottom:hover{
			background-color: $white;
			@include shadow;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
			}
		}
	}

	.ml_i_container_off{
		background-color:$inputblue;
			
		i {
			color: $black;
		}
		&:hover > i {
			color: black;
			font-weight: bold;
		}

		&.ml-icon-btn-up{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
				transform: rotate(180deg);
			}
		}
	   
		&.ml-icon-btn-down{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
			}
		}
	  
		&.ml-icon-btn-left{
			height: 55px;
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
				transform: rotate(90deg);
			}
		
		}


		&.ml-icon-btn-right{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_desplegar.svg');
				transform: rotate(270deg);
			}
		}
	   

		&.ml-icon-btn-forward{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/doble-flecha.svg');
				transform: rotate(270deg);

			}
		}

		&.ml-icon-btn-to-top{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
				transform: rotate(180deg);
			}
		}
	   
		&.ml-icon-btn-to-bottom{
			span{
				background-image: url('https://blog.meludus.com/wp-content/uploads/img-meludus/icons/b/flecha_con-tope.svg');
			}
		}
	}

	.toolbox_options{
		margin: 5px;
		border-left: 2px solid var(--primary);
		display: flex;
		cursor: pointer;
		> div{
			color: var(--primary);
			font-size: 2.5rem;
			transform: rotate(90deg);
			pointer-events: none;
		}
		&:hover{
			> div{ transform: scale(1.1) rotate(90deg); }
		}
	}

	.toolbox_options_body{
		position: absolute;
		background-color: white;
		padding: 15px;
		bottom: -48px;
		right: 0px;
		width: 130px;
		border-radius: 10px;
		@include shadow;
		p {
			cursor: pointer;
			&:hover{ font-weight: bold; }
			&.disabled{
				pointer-events: none;
				opacity: .4;
			}
		}
	}
}

.show_toolbox { animation: show_toolbox .5s ease-in-out 0s forwards; }

@keyframes show_toolbox {
  to { opacity: 1; }
}

.hide_toolbox {
	animation: hide_toolbox .5s ease-in-out 0s forwards;
}

@keyframes hide_toolbox {
  to { opacity: 0; }
}
